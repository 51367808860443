body.page-rendering-schools-filter {
  .text-resulte {
    color: black;
    font-family: "Myriad Pro";
    font-size: 21px;
    font-weight: 400;
    line-height: 29px;
  }
  .result-request {
    border: 4px solid #27aee6;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin-bottom: 40px;
    div {
      color: black;
      font-family: 'Open Sans';
      font-size: 18px;
      line-height: 29px;
      font-weight: 400;
      span {
        font-weight: 700;
        margin-left: 3px;
      }
    }
    .contry {
      width: 290px;
    }
    .age {
      width: 290px;
    }
  }
  .results_education {    
    .education_find_right {
      @include respond-down (lg) {
        width: 610px;
        margin: 0 auto;
      }
      @include respond-down (md) {
        width: 290px;
        margin: 0 auto;
      }
      .education_find_country {
        padding-bottom: 20px;
      }
      .education_find_price {
        padding-top: 10px;
      }
      img {
        min-width: 100%!important;
        max-width: unset;
        height: auto;
      }
      p {
        padding-top: 0;
        padding-bottom: 0;
      }
      .rating-detail {
        font-size: 13px;
        padding: 10px 15px;
        border-radius: 5px;
        background: #E4F7FE;
        display: block;
        //margin-left: 10px;
        i {
          display: inline-block;
          margin-right: 3px;
          width: 16px;
          height: 16px;
          background: url(/sites/all/themes/uwstar/assets/images/detailStar.svg) no-repeat center center;
          background-size: 16px 16px;
        }
      }
      .education_sorts {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        @include respond-down (md) {
          flex-direction: column-reverse;
        }
        .list_schools_count {
          // padding-bottom: 10px;
          padding-top: 10px;
          color: black;
          font-family: 'Open Sans';
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          span {
            font-weight: 700;
          }
        }
        .list_schools_sort {
          color: black;
          font-family: 'Open Sans';
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          // padding-bottom: 10px;
          padding-top: 10px;
          @include respond-down (md) {
            padding-top: 0px;
          }
          ul {
            position: relative;
            list-style: none;
            display: inline-flex;
            padding-left: 0px;
            li {
              padding-left: 10px;
              a {
                color: black;
                border-right: 0;
                border-left: 0;
                border-top: 0;
                border-bottom: 1px;
                border-style: dashed;
                text-decoration: none;
                &:hover {
                  color: #428bca;
                  border-bottom: 1px solid #428bca;
                  border-style: dashed;
                }
              }
            }
            li.center {
              display: inline;
              text-indent: -9999px;
              width: 2px;
              height: 29px;
              background-color: grey;
              padding-left: 0;
              margin-left: 10px;
            }
            li.first {
              padding-right: 10px;
              @include respond-down (md) {
                padding-left: 0px;
              }
            }
            li.active {
              &:before {
                content: '';
                background: url(../images/down-arrow.svg) center no-repeat;
                width: 10px;
                height: 20px;
                position: absolute;
                background-size: cover;
                left: 142px;
                top: 5px;
              }
              a {
                color: #428bca;
                border-bottom: 1px solid #428bca;
                border-style: solid;
              }
            }
            li.active-price {
              padding-right: 15px;
              &:after {
                content: '';
                background: url(../images/down-arrow.svg) center no-repeat;
                width: 10px;
                height: 21px;
                position: absolute;
                background-size: cover;
                right: 0px;
                top: 6px;
              }
              a {
                color: #428bca;
                border-bottom: 1px solid #428bca;
                border-style: solid;
              }
            }
            li.price-desc {
              padding-right: 15px;
              &:after {
                content: '';
                background: url(../images/down-arrow.svg) center no-repeat;
                transform: rotate(180deg);
                width: 10px;
                height: 21px;
                position: absolute;
                background-size: cover;
                right: 0px;
                top: 6px;
              }
              a {
                color: #428bca;
                border-bottom: 1px solid #428bca;
                border-style: solid;
              }
            }
            li.alphabet-desc {
              padding-right: 15px;
              &:after {
                content: '';
                background: url(../images/down-arrow.svg) center no-repeat;
                transform: rotate(180deg);
                width: 10px;
                height: 20px;
                position: absolute;
                background-size: cover;
                left: 142px;
                top: 5px;
              }
              a {
                color: #428bca;
                border-bottom: 1px solid #428bca;
                border-style: solid;
              }
            }
          }
        }  
      }
    }
    .plashka {
      padding-top: 5px;
      background-color: #d2f2ff;
      border-bottom: 9px solid #27aee6;
      h2 {
        text-align: center;
        font-size: 22px;
        margin-top: 0px;
        margin-bottom: 5px;
        @include respond-down (lg) {
          text-align: center;
        }
        @include respond-down (md) {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}
body.page-rendering-schools-filter-resultshtml .education_find_wrap .education_find_subwrap {
  @include respond-down (lg) {
    display: unset;
  }
}
.page-rendering-schools-filter-resultshtml .education_find_wrap .education_find_subwrap .education_find_text .course_more {
  @include respond-down (lg) {
    margin: 15px 0;
    width: 100%;
    display: flex;
    position: unset!important;
  }
}
.page-rendering-schools-filter-resultshtml .education_find_wrap .education_find_subwrap .education_find_text {
  @include respond-down (lg) {
    padding: 0!important;
  }
}
.page-rendering-schools-filter-resultshtml .education_find_wrap .education_find_subwrap .education_find_img {
  @include respond-down (lg) {
    padding: 0!important;
  }
}
.education_find_right .button-show-better {
  display: inline-block;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
  .show-better-school {
    color: #fefefe;
    background-color: #ff7200;
    font-size: 18px;
    font-weight: 700;
    line-height: 29px;
    padding: 12px 37px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: #27aee6;
    }
  }
}