.view-id-slider_front {
  position: relative; 
  .swiper-wrapper {
    margin: 0;
    padding: 0;
  }
  .swiper-slide {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .views_slideshow_swiper_main {
    position: relative;
    z-index: 2;
  }
  .views-slideshow-controls-bottom {
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .views-slideshow-controls-text {
    @extend .container;
    position: relative;
  }
  .views-slideshow-controls-text-pause {
    display: none;
  }
  .views-slideshow-controls-text-previous,
  .views-slideshow-controls-text-next {
    font-size: 0;
    height: 54px;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    width: 100px;
    z-index: 2;
  }
  .views-slideshow-controls-text-previous {
    background: rgba(255,255,255,.2) url(../images/front_slider_prev.png) right 20px center no-repeat;
    left: 0;
  }
  .views-slideshow-controls-text-next {
    background: rgba(255,255,255,.2) url(../images/front_slider_next.png) left 20px center no-repeat;
    right: 0;
  }
  .views_slideshow_controls_text {
    width: 100%!important;
  }
}

.node-front-slider {
  .field-name-field-front-slider-image {
    clip: rect(auto,auto,auto,auto);
    position: relative;
    width: 100%;
    &:before {
      background: rgba(0,0,0,.3);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .background-image-formatter {
    height: 600px;
  }
  .vertical-align {
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .field-item {
    > p {
      text-align: center;
    }
  }
  .main-title {
    color: #fff;
    text-align: center;
    .delay-1 {
      color: #fff;
      font-weight: 700;
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 20px;
      text-transform: none;
    }
    .delay-2 {
      font-size: 40px;
      line-height: 80px;
    }
  }
  .c-button {
    width: 280px;
  }
}