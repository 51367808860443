.baner-tabs .tab-content .tab-pane,
.b-consult-form,
.institution-form,
.startravel-search-top,
.article-education-language-form-wrapper {

	.form-control.jq-selectbox{
		width: 100%;
		height: initial;
		padding: 0;
		line-height: initial;
		color: #777;
		font-size: 14px;
		background: none;
		border: none;
		border-radius:0;
		box-shadow: none;
		transition: none;
		margin-left: auto;
		margin-right: auto;
	}

	.jq-selectbox__select {
		width: 100%;
		height: 50px;
		padding: 0 20px;
		margin-left: auto;
		margin-right: auto;
		z-index: 2;
		border:none;
	    border-radius: 25px;
		background-color: #fff;
		position: relative;
		border: 1px solid #b9b9b9;
		@include respond-down(lg) {
			height: 45px;
		}
	}

	.jq-selectbox__select-text {
		display: inline-block;
		color: #777;
		font-size: 14px;
		@include respond-down(lg){
			font-size: 20px;
			line-height: 45px;
			padding-left: 5px;
		}
		@include respond-down(md){
			font-size: 15px;
		}
	}

	.jq-selectbox__trigger {
		content: '';
		position: absolute;
		width: 10px;
		height: 14px;
		background-image: url(../images/select_arrows.png);
		background-position: right0 center;
		background-repeat: no-repeat;
		top:0;
		bottom: 0;
		margin: auto 0;
		right: 10px;
	}
	.jq-selectbox__trigger-arrow {
		display: none;
		position: absolute;
		top: 14px;
		right: 12px;
		width: 0;
		height: 0;
		border-top: 5px solid #999;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
	}

	.jq-selectbox.disabled .jq-selectbox__select {
		border-color: #CCC;
		background: #F5F5F5;
		box-shadow: none;
		color: #888;
	}

	.jq-selectbox__dropdown {
		box-sizing: border-box;
		width: 100%;
		margin: 0;
		padding: 12px0 12px0;
		z-index: 1;
		/*height: 277px!important;*/
		background-color: white;
		top: 58px!important;
		border: 1px solid #eaeaea;
	}

	.jq-selectbox ul {
		margin: 0;
		padding:0 0 0 0;
		list-style: none;
		text-align: left;
		width: 100%;
		height: 100%;
	}
	.jq-selectbox li {
		padding: 2px 0 2px 15px;
		font-family: "Open Sans", sans-serif;
		font-size: 14px;
		font-weight: 400;
		position: relative;
		color: #000;
	}

	.jq-selectbox li.selected {
		color: #fff;
		background-color: #23b0e8;
	}
	.jq-selectbox li:hover {
		color: #fff;
		background-color: #23b0e8;
	}
}