.special-slider-pane {
  padding-bottom: 55px;
  .views-slideshow-controls-text {
    display: inline-block;
    padding: 0 0 10px 0;
    width: 100%;
  }
  .views-slideshow-controls-text-pause {
    display: none;
   }/*
.views-slideshow-controls-text-previous,
.views-slideshow-controls-text-next {
  border: 1px solid #23b0e8;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  height: 32px;
  text-decoration: none;
  text-indent: -9999px;
  transition: all .35s ease-in-out;
  width: 32px;
}
.views-slideshow-controls-text-previous {
  background: #23b0e8 url(../images/slider_arrow_prev_white.png) center no-repeat;
  &:hover {
    background: transparent url(../images/slider_arrow_prev_blue.png) center no-repeat;
  }
}
.views-slideshow-controls-text-next {
  background: #23b0e8 url(../images/slider_arrow_next_white.png) center no-repeat;
  &:hover {
    background: transparent url(../images/slider_arrow_next_blue.png) center no-repeat;
  }
}
.slick__arrow {
  button {
    border: 1px solid #23b0e8;
    border-radius: 50%;
    display: inline-block;
    font-size: 0;
    height: 32px;
    text-decoration: none;
    text-indent: -9999px;
    transition: all .35s ease-in-out;
    width: 32px;
  }
  .slick-prev {
    background: #23b0e8 url(../images/slider_arrow_prev_white.png) center no-repeat;
    &:hover {
      background: transparent url(../images/slider_arrow_prev_blue.png) center no-repeat;
    }
  }
  .slick-next {
    background: #23b0e8 url(../images/slider_arrow_next_white.png) center no-repeat;
    &:hover {
      background: transparent url(../images/slider_arrow_next_blue.png) center no-repeat;
    }
  }
} */
  .views_slideshow_swiper_main {
    left: -3px;
    overflow: hidden;
    position: relative;
    width: calc(100% + 6px);
  }
  .swiper-slide {
    display: inline-block;
    float: left;
  }
  .views-row {
    display: inline-block;
    float: left;
  }
}

.node-offers-teaser {
  box-shadow: 4px 3px 5px 0 rgba(0,0,0,.03);
  display: inline-block;
  float: left;
  position: relative;
  .field-name-field-image-special {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 157px;
    overflow: hidden;
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .field-name-field-date-end-special {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 12px;
    padding: 10px 0;
    text-transform: uppercase;
  }
  .title {
    border: 1px solid #efefef;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    padding: 24px 20px;
    position: relative;
    text-align: left;
    width: 100%;
  }
  .like-h4 {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    height: 80px;
    line-height: 18px;
    margin-bottom: -4px;
    overflow: hidden;
    text-transform: uppercase;
    padding: 10px 0;
  }
}