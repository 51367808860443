.view-id-blog {
  .views-row {
    margin-bottom: 70px;
    padding-left: 30px;
  }
  .button-s-2 {
    display: none;
  }
}
.view-id-blog_tag_materials {
  .views-row {
    margin-bottom: 70px;
    padding-left: 30px;
  }
  .button-s-2 {
    display: none;
  }
}
.pane-menu-menu-blog-category {
  margin-bottom: 37px;
  .pane-content {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin: 0 0 1px 15px;
        a {
          background: #f8f8f8;
          border-radius: 5px;
          color: #b9b9b9;
          display: block;
          font-size: 14px;
          font-weight: 700;
          text-decoration: none;
          text-transform: uppercase;
          padding: 13.5px 20px 13.5px 22px;
          &:hover {
            background: #23b0e8;
            color: #fff;
          }
        }
      }
    }
  }
}
.view-id-blog_tags {
  .view-content {
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .views-row {
      float: left;
      list-style: none;
      margin-left: 15px;
      margin-right: 5px;
      margin-bottom: 2px;
      padding: 0;
      a {
        background: #f8f8f8;
        border-radius: 25px;
        border: 1px solid #f8f8f8;
        color: #bebebe;
        display: inline-block;
        font-size: 10px;
        font-weight: 400;
        height: 30px;
        line-height: 28px;
        padding: 0 18px;
        text-align: center;
        text-decoration: none;
        &:hover {
          background: #23b0e8;
          color: #fff;
        }
      }
    }
  }
}
.node-type-article {
  .left {
    float: left;
    margin: 0 20px 5px 0;
    width: 35%;
  }
  .right {
    float: right;
    margin: 0 0 5px 20px;
    width: 35%;
  }
}
.node-type-article {
  .pane-title {
      padding-top: 0;
      padding-bottom: 30px;
  }
}