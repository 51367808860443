.page-course,
.page-university,
.page-shkoly {
  .region-content {
    background: #ffffff;
    padding-top: 0px;
    .school-inner-container {
      .right-sidebar-school {
        margin-bottom: 35px;
        .title_wrap {
          h3 {
            margin-top: 0;
          }
        }
        .school_after_title {
          .title {
            font-family: "Open Sans", sans-serif;
            font-size: 18px;
            line-height: 29px;
            color: #364e67;
            font-weight: 400;
          }
        }
        .school-inner-modal1 {
          float: none;
          margin-right: 15px;
          @include respond-down(md) {
            margin-right: 15px;
            margin-right:0;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
          }
        }
        .school-inner-modal2 {
          float: none;
          @include respond-down(md) {
            display: flex;
            justify-content: center;
          }
        }
      }
      .slider-wth-thumbs {
        .swiper-slide {
          height: auto;
          img {
            margin: 0;
            margin-top: 13px;
          }
        }
        .all-foto {
          text-align: right;
          @include respond-down(md) {
            text-align: center;
          }
          @include respond-down(lg) {
            text-align: left;
          }
          a {
            color: #2d3645;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 29px;
            text-decoration: underline;
          }
        }
      }
      .tab-wrapper {
        .nav-tab {
          .nav-tab-item {
            background-color: #eff8ff;
            border: 1px solid #97c6e9;
            border-radius: 0;
            color: #406184;
            font-family: "Open Sans", sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 40px;
            padding-top: 7px;
            text-transform: none;
            width: 282.9px;
            padding: 0;
            text-align: center;
            letter-spacing: -1px;
            height: 40px;
            @include respond-down(lg) {
              width: 100%;
              border-left: none;
              border-right: none;
            }
            @include respond-down(md) {
              width: 100%;
            }
          }
          .active {
            border-bottom: none;
            background-color: #fff;
            color: #3c6fa4;
          }
        }
        .tabs-content {
          .tab-info {
            border: 1px solid #97c6e9;
            h2, h3, p, ul {
              color: #364e67;
              font-family: "Open Sans", sans-serif!important;
              font-size: 18px!important;
              line-height: 21px;
            }
            @include respond-down (lg) {
              border: none;
            }
            .school-inner-info-container {
              span, div {
                color: #364e67;
                font-family: "Open Sans", sans-serif!important;
                font-size: 18px!important;
                line-height: 21px;
              }
              .school-price-info {
                padding-top: 10px;
              }
            }
            .school-inner-modal1 {
              float: none;
              @include respond-down(lg) {
                display: flex;
                justify-content: center;
              }
              @include respond-down(md) {
                display: flex;
                justify-content: center;
              }
              a {
                border-radius: 0;
                @include respond-down(md) {
                  padding: 14px 30px;
                }
              }
            }
            .detail-content-block {
              margin-bottom: 15px;
              h2, h3, p, ul {
                color: #364e67;
                font-family: "Open Sans", sans-serif!important;
                font-size: 18px!important;
                line-height: 21px;
              }
            }
          }
        }
      }
      .slider-wth-thumbs {
        .slick-prev {
          position: absolute;
          left: -25px;
          background: url(../images/arr_prev.png) center no-repeat;
          width: 21px;
          height: 32px;
          font-size:0;
          border: none;
          top: 80px;
          outline: none;
          cursor: pointer;
        }
        .slick-next {
          position: absolute;
          right: -25px;
          background: url(../images/arr_next.png) center no-repeat;
          width: 21px;
          height: 32px;
          font-size:0;
          border: none;
          top: 80px;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}
.node-type-page-with-cards {
  .field-type-text-with-summary {
    .right {
      float: right;
      width: 35%;
    }
  }
}

.node-type-page-with-cards {
  .field-type-text-with-summary {
    .icon-block {
      img {
        float: unset;
        width: unset;
      }
    }
  }
}