.node-type-offers {
  .region-content {
    padding-top: 0;
  }
  .uwstar-sidebar-layout {
    background: transparent;
  }
  .uwstar-sidebar-layout .middle-row {
    padding-top: 0;
  }
  .pane-node-title{
    margin-top: 15px;
  }
  #block-webform-client-block-7866 {
    .form-item {
      margin: 0;
      .agree-dark {
        margin-top: 10px;
      }
    }
    h4.block__title {
      margin: 0;
    }
    p {
      display: none;
    }
  }
}