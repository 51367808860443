#block-block-58 {
  .ip-region{
    cursor: pointer;
    position: relative; 
    .fa {
      position: absolute;
      top: 8px;
      right: 10px;
      color: #fff;
    }
    .angle {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      background-color: #4bade1;
      a {
        color: #fff
      }
    }
    .active {
      //border: 1px solid red;
      border-radius: 5px;
      font-family: 'Open Sans', sans-serif;
      padding: 5px 18px 6px;
      font-size: 14px; 
      color: #fff;
      width: 302px;
      background-color: #4bade1;
      margin-left: 60px;
      margin-top: 20px;
      // margin-top: 10px;
      @include respond-down(lg) {
        margin-top: 15px;
        margin-left: 0;
        width: 228px;
        font-size: 12px;
      }
      @include respond-down(md) {
        margin-bottom: 14px;
        width: 230px;
      }
    }
    ul {
      right: 10px;
      position: absolute;
      z-index: 10;
      background: #fff;
      padding: 0;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .1);
      display: none;
      @include respond-down(lg) {
        width: 215px;
        z-index: 101;
      }
      @include respond-down(md) {
        top: 35px;
      }
      li {
        cursor: pointer;
        list-style: none;
        width: 285px;
        padding: 6px 18px;
        font-size: 13px;
        color: #777;
        font-family: 'Open Sans', sans-serif;
        @include respond-down(lg) {
          width: 215px;
        }
        &:hover {
          background: #f8f8f8;
          color: #222;
        }
      }
    }
  }
}
.your-city {
  .header-top {
    height: 100px!important;
    @include respond-down(lg) {
      height: 145px!important;
    }
    @include respond-down(md) {
      height: auto!important;
    }
    #block-block-58 {
      .ip-region {
        .fa {
          top: 53px;
        }
        .angle {
          border-radius: 5px;
          width: 302px;
          padding: 5px 18px 6px;
          margin-left: 60px;
          margin-top: 10px;
          @include respond-down(lg) {
            margin-left: 0;
            width: 228px;
            font-size: 12px;
          }
           @include respond-down(md) {
            width: 230px;
          }
          a {
            padding-right: 120px;
            @include respond-down(lg) {
              padding-right: 65px;
            }
          }
        }
        .active {
          margin-top: 10px;
        }
        ul {
          @include respond-down(md) {
            top: 80px;
          }
        }
      }
    }
  }
}