.page-page-404 {
  .region-content {
    padding-top: 50px;

  }
  .pg404 {
    text-align: center;
    span {
      color: #27aee6;
      font-family: "Open Sans";
      font-size: 243px;
      font-weight: 600;
      line-height: 29px;
      position: relative;
      @include respond-down(md) {
        font-size: 130px;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 28px;
        left: calc(50% - 66px);
        width: 132px;
        height: 8px;
        background-color: #27aee6;
        @include respond-down(md) {
          width: 66px;
          height: 4px;
          left: calc(50% - 33px);
        }
      }
    }
    p {
      color: #364e67;
      font-family: "Open Sans";
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
      margin-top: 60px;
      margin-bottom: 10px;
      @include respond-down(md) {
        font-size: 20px;
        margin-top: 70px;
      }
    }
    a {
      color: white;
      font-family: "Open Sans";
      font-size: 18px;
      font-weight: 700;
      line-height: 29px;
      width: 281px;
      height: 51px; 
      background-color: #27aee6;
      border: 4px solid ;
      display: inline-block;
      padding: 7px 0; 
    }
  }
}