@media (min-width: $screen-md) {
  .front-country-pane {
    .top {
      .col-md-4 {
        &:nth-child(1) {
          padding-left: 0;
          padding-right: 20px;
        }
        &:nth-child(2) {
          padding-left: 10px;
          padding-right: 10px;
        }
        &:nth-child(3) {
          padding-left: 20px;
          padding-right: 0;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .school-search-spec,
  .school-info-countries {
    .big-images-row {
      .col-md-4 {
        &:nth-child(1) {
          padding-left: 0;
          padding-right: 20px;
        }
        &:nth-child(2) {
          padding-left: 10px;
          padding-right: 10px;
        }
        &:nth-child(3) {
          padding-left: 20px;
          padding-right: 0;
        }
        img {
          width: 100%;
        }
      }
    }
    .small-images-row {
      margin-left: -15px;
      width: calc(100% + 30px);
      .col-md-2 {
        &:nth-child(1) {

        }
      }
    }
  }
}
@media (min-width: $screen-lg) {
  #header {
    .nav-menu-icon {
      display: none;
    }
  }
  #mobile-menu-container {
    display: none;
  }
  .school-search-spec,
  .school-info-countries {
    .small-images-row {
      margin-left: -35px;
      width: calc(100% + 70px);
    }
  }
}
@media (max-width: $screen-md-max) {
  #main-menu {
    display: none;
  }
}
//min-width: 992px and max-width: 1199px
@media (min-width: $screen-md) and (max-width: $screen-md-max) {
  #footer {
    .footer-block {
      padding-right: 0px;
    }
  }
}
//min-width: 768px and max-width: 991px
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  #header {
    .header-top-menu {
      display: none;
    }
  }
  #footer {
    .footer-block {
      padding-right: 0;
    }
    .footer-top {
      .block-menu {
        padding-bottom: 70px;
      }
    }
    .footer-bottom-menu {
      float: right;
      .block__content {
        ul {
          margin-right: 0;
        }
      }
    }
    .footer-copyright {
      text-align: center;
      width: 100%;
    }
  }
}
//max-width: 767px
@media (max-width: $screen-sm-min) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  #header {
    .header-top-menu {
      display: none;
    }
    .block-silver-popup {
      //display: none;
    }
    .search-block {
      width: 100%;
      .views-exposed-form {
        .views-exposed-widget {
          padding: 0;
          width: 100%;
        }
      }
    }
    .header-phone {
      width: 100%;
      .block__content {
        text-align: center;
      }
    }
  }
  #footer {
    .footer-block {
      padding-right: 0;
    }
    .footer-top {
      .block__title {
        text-align: center;
        br {
          display: none;
        }
      }
      .block-menu {
        padding-bottom: 40px;
      }
      ul.menu {
        li {
          margin-left: 0;
          a {
            text-align: center;
          }
        }
      }
    }
    .footer-top-info {
      .block__content {
        text-align: center;
      }
    }
    .footer-logo {
      width: 100%;
      .block__content {
        text-align: center;
      }
    }
    .footer-bottom-menu {
      width: 100%;
      .block__content {
        text-align: center;
        ul {
          margin-left: 0;
          margin-right: 0;
          li {
            display: inline-block;
            float: none;
          }
        }
      }
    }
    .footer-copyright {
      width: 100%;
      .block__content {
        text-align: center;
      }
    }
  }
}
.container-720 {
  width: 720px;
  padding-left: 15px;
  padding-right: 15px;
  @include respond-down(lg) {
    width: unset;
  }
}








