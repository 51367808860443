.view-id-reviews_high_middle_langcourses,
.view-id-reviews_front {
  .views-slideshow-controls-text-pause {
    display: none;
  }
  .views-slideshow-controls-text-previous,
  .views-slideshow-controls-text-next {
    border: 0;
   // background: rgba(35,176,232,.7);
    height: 40px;
    position: absolute;
    text-align: center;
    top: 50%;
    transition: all 320ms cubic-bezier(.565,.42,.355,1.155);
    transform: translateY(-50%);
    width: 40px;
    z-index: 5;
    a {
      @extend .fa;
      color: #fff;
      display: block;
      font-size: 0;
      position: relative;
      text-indent: -99999px;
      text-decoration: none;
      &:before {
        content: '';
        color: inherit;
        font-size: 60px;
        left: 0;
        position: absolute;
        text-align: center;
        text-indent: 0;
        top: 13px;
        width: 100%;
      }
      &:hover {
        color: #222;
      }
    }
  }
  // .views-slideshow-controls-text-previous {
  //   border-radius: 0 5px 5px 0;
  //   left: calc((100% - 1225px) / 2);
  //   a {
  //     @extend .fa-angle-left;
  //     &:before {
  //       content: "\f104";
  //     }
  //   }
  // }
  // .views-slideshow-controls-text-next {
  //   border-radius: 5px 0 0 5px;
  //   right: calc((100% - 1240px) / 2);
  //   a {
  //     @extend .fa-angle-right;
  //     &:before {
  //       content: "\f105";
  //     }
  //   }
  // }
  .swiper-slide {
    padding: 50px 15px 0 15px;
  }
  .views-row {
    background: #fff;
    box-shadow: 4px 3px 5px 0 rgba(0,0,0,.03);
    display: inline-block;
    float: left;
    padding: 40px 30px;
    position: relative;
    width: 100%;
  }
  .views-field-field-review-photo {
    height: 100px;
    left: 0;
    position: absolute;
    text-align: center;
    top: -50px;
    width: 100%;
    img {
      border-radius: 50%;
      height: 100%;
      display: block;
      left: 10px;
      margin: auto;
      width: auto;
    }
  }
  .views-field-field-review-name-age {
    font-weight: 700;
    font-size: 20px;
    padding: 20px 0;
    text-align: center;
  }
  .views-field-body {
    font-size: 14px;
  }
}

.pane-menu-menu-category-otzyvy {
  .pane-content {
    ul {
    overflow: hidden;
    border-radius: 5px;
      li {

        a {
              font-size: 13px;
          line-height: 16px;
          font-weight: 400;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #777;
          display: block;
          background: #fff;
          margin-bottom: 1px;
          padding: 14.5px 25px;
          &:hover {
            color: #fff;
            background: #23b0e8;
          }
        }
      }
    }
  }
}
.pane-menu-menu-category-blog-new {
  .pane-title {
        font-size: 22px;
    margin-bottom: 27px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .nav {
    margin-bottom: 1px;
    li {
      margin-bottom: 1px;
      a {
        background: #f8f8f8;
        color: #b9b9b9;
        display: block;
        font-size: 14px;
        border-radius: 5px;
        &:hover {
          color: #fff;
          background: #23b0e8; 
        }
      }
    }
  }
}
.view-id-review {
  h3 {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    padding: 14px 45px 14px 70px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    background: #23b0e8;
    a {
      color: white;
      text-decoration: none;
    }
  }
  .view-content {
    background: #F8F8F8;
    .views-row  {
      padding: 20px;
      background: #fff;
      border-radius: 5px;
      margin-bottom: 30px;
      span {
        color: #000;
        font-weight: 700;

      }
      .views-field-field-review-name-age {
        color: #000;
        font-weight: 700;
      }
    }
  }
}
.uwstar-sidebar-layout {
  background: #F8F8F8;
  .middle-row {
    @include respond-down (lg) {
      display: flex;
      flex-direction: column-reverse;
    }
    .uwstar-sidebar-content-col {
      @include respond-down (lg) {
        left: 0;
        width: 100%;
      }
    }
    .sidebar-col {
      @include respond-down (lg) {
        right: 0;
        width: 100%;
      }
    }
  }
}