.hotel-item {
  box-shadow: 4px 3px 5px 0 rgba(0,0,0,.03);
  display: inline-block;
  float: left;
  position: relative;
  width: 100%;
  height: 405px;
  .radius-top {
    height: 157px;
  }
  img {
    width: 100%;
    height: 167px;
  }
  .title {
    border: 1px solid #f7f7f7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 24px 20px;
    position: relative;
    text-align: left;
    width: 100%;
  }
  p {
    font-weight: 700;
    margin-bottom: 25px;
    padding: 10px 0;
  }
  .hotel-person {
    color: #23b0e8;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 20px;
    text-transform: none;
    span {
      font-weight: 700;
    }
  }
}

//Мы рекомендуем
.startravel-recommeded-block-content {

}
.simple-tab {
  .nav-tab {
    font-size: 0;
    position: relative;
    top: 1px;
    z-index: 2;
    .nav-tab-item {
      border-radius: 3px;
      cursor: pointer;
    }
  }
  .tab-info {
    border: 1px solid #eaeaea;
    display: none;
    padding: 26px 32px;
    @include respond-down (lg) {
      border: none;
    }
    &.active {
      display: block;
    }
  }
  &.type-2 {
    .tab-nav-wrapper {
      margin-bottom: 50px;
      text-align: center;
    }
    .nav-tab-item {
      border: none;
      color: #333;
      display: inline-block;
      font-size: 12px;
      font-weight: 700;
      height: 39px;
      line-height: 39px;
      margin-right: 5px;
      padding: 0 14px;
      position: relative;
      text-transform: uppercase;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 10px 0;
        border-color: transparent;
      }
      &.active {
        background: #23b0e8;
        color: #fff;
        &:before {
          border-color: #23b0e8 transparent transparent;
        }
      }
      &:hover {
        color: #f60;
      }
       @include respond-down (lg) {
      background: unset;
    }
    }
    .tab-info {
      border: none;
      padding: 0;
    }
  }
}

.startravel-search-top {
  background: #E2EDF3;
  padding-bottom: 90px;
  .form-actions,
  .form-item {
    @extend .col-md-3;
    @extend .col-xs-12;
    margin: 0 0 15px 0;
    padding: 0 15px;
  }
  select {
    appearance: none;
    background-color: #fff;
    border-radius: 25px;
    border: none;
    color: #777;
    font-size: 14px;
    height: 50px;
    outline: none;
    padding: 0 20px;
    transition: all .3s ease-in;
    width: 100%;
  }
  input[type=submit] {
    @extend .c-button;
    @extend .w100;
    @extend .h50;
    background-color: #23b0e8;
    border: 2px solid #23b0e8;
    outline: none;
    &:hover {
      background: none;
      color: #23b0e8;
    }
  }
}

.hotel-small {
  text-align: center;
  margin: 0 auto;
  /*width: 130px;*/
  &:hover{
    h4 {
      margin-top: 13px;
      a {
        color: #0095d8;
      }
    }
  }
  .replace-h4 {
      //margin-left: 15px;
      text-transform: uppercase;
      height: inherit;
      text-align: left; 
      a {
        text-decoration: none;
        color: #222;
        &:hover {
          color: #428bca;
        }
      }
    }
  .hotel-img {
    .img-responsive {
      border-radius: 5px;
      max-width: unset;
    }
  }
  .tour-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 5px;
   
    z-index: 999;
    &:hover {
      background: rgba(0, 0, 0, .6);
      opacity: 0.8;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .hotel-img {
    display: block;
    position: relative;
    width: 100%;
    img {
      margin-left: auto;
      margin-right: auto;
    }
    /*&:after {
      background: rgba(0,0,0,.6);
      border-radius: 5px;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }*/
    //&:hover {
     /* &:after {
        opacity: 1;
      }*/

    //}
  }
  h4 {
    margin-top: 13px;
    // text-align: left;
    a {
      text-decoration: none;
      color: black;
      font-family: "HeliosCondBold";
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 24px;
    }
  }
}

.school_block {
  .table {
    margin-bottom: 20px;
    max-width: 100%;
    width: 100%;
    th {
      border-top: 0;
      border-bottom: none;
      padding: 15px 25px;
      @include respond-down (md) {
        padding: 10px;
      }
    }
    td {
      border-top: 1px solid #ddd;
      padding: 16px 25px 12px;
      @include respond-down (md) {
        padding: 10px;
      }
    }
  }
}

.school-info-countries {
  .big-images-row {
    a {
      display: block;
    }
    margin-bottom: 18px;
    div[class*="col-"] {
      margin-bottom: 30px;
      @media (max-width: 991px) {
        display: flex;
        flex-wrap: wrap; 
        justify-content: center; 
      }
    }
  }
  .small-images-row {
    @media (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    div[class*="col-"] {
      height: 190px;
       @media (max-width: 991px) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

.school-search-spec {
  .small-images-row {
    div[class*="col-"] {
      height: 190px;
    }
  }
}

.school-search-programm {
  .small-icon-row {
    .hotel-img {
      /*height: 80px;
      margin: 0 auto;
      width: 80px;*/
      margin-bottom: 8px;
    }
  }

  .col-md-4{
    height: 192px;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    &:hover{
      opacity: .7;
    }
    &:nth-child(1){
      background-color: #0095d8;
    }
    &:nth-child(2){
      background-color: #29b6f6;
    }
    &:nth-child(3){
      background-color: #e2edf3;
    }
  }
  .hotel-small{
    h4{
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      a {
        color: white;
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
.school-page-info {
  .b-steps {
    @media (max-width: 640px) {
      .col-mob-12 {
        width: 100%;
      }
    }
  }
  .content {
    h2,
    h3 {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
}

//Отзывы
.pane-reviews-high-middle-langcourses-panel-pane-1,
.pane-reviews-high-middle-langcourses-panel-pane-2,
.pane-reviews-high-middle-langcourses-panel-pane-3 {
  @extend .pane-reviews-front-panel-pane-1;
}

//Внутренняя страница
.school-content-inner {
  //padding-right: 10px;
}
.right-sidebar-school {
  padding-left:0;
  margin-bottom: 30px;
  @include respond-down(md) {
    margin-bottom: 5px;
  }
}
.school-gallery-container {
  .school-gallery-top {
    .swiper-slide {
      cursor: pointer;
      height: 443px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      @include respond-down(lg) {
        justify-content: start;
      }
      img {
        max-width: 530px;
        max-height: 100%;
        @include respond-down(md) {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
  .school-gallery-thumbs {
    .swiper-slide {
      cursor: pointer;
      position: relative;
      &:before {
        bottom: 0;
        border: 10px solid #2d3e50;
        content: '';
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: all .3s ease-in;
        z-index: 10;
      }
      &.current,
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}
.school-price-info {
  font-weight: 700;
}
//Подать заявку
.school-inner-modal1 {
  display: inline-block;
  float: left;
  .block__content {
    a.ctools-use-modal {
      @extend .c-button;
      // @extend .bg-white;
      // @extend .w100;
      background-color: #4c965a;
      color: #ffffff;
      font-size: 19px;
      font-weight: 700;
      line-height: 21px;
      outline: none;
      text-align: center;
      padding: 14px 58px; 
      border-radius: 0;
      &:hover {
        background-color: #27aee6!important;
        color: #fff;
      }
      @include respond-down(md) {
        padding: 14px 80px;
      }
    }
  }
}
//Задать вопрос
.school-inner-modal2 {
  display: inline-block;
  .block__content {
    a.ctools-use-modal {
      @extend .c-button;
      // @extend .w100;
      border: 4px solid #364e67;
      color: #364e67;
      font-size: 19px;
      font-weight: 700;
      line-height: 21px;
      outline: none;
      text-align: center;
      padding: 10px 54px;
      border-radius: 0;
      &:hover {
        background-color: #364e67!important;
        border: 4px solid #364e67!important;
        color: #fff;
      }
      @include respond-down(md) {
        padding: 14px 78px;
      }
    }
  }
}
//Бесплатная консультация
#block-multiblock-4 {
  .block__content {
    a.ctools-use-modal {
      padding: 17px 31px;
      font-size: 16px;
      line-height: 17px;
      margin-top: 20px;
      @include respond-down(md) {
        padding: 17px 15px;
        font-size: 16px;
        line-height: 17px;
      }
    }
  }
}
.institution-review {
  padding-bottom: 20px;
  p {
    color: #000 !important;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0 !important;
    padding-bottom: 12px;
  }
}
#webform-ajax-wrapper-4044 {
  .alert {
    display: none;
  }
}
.webform-client-form-4044 {
  margin: auto;
  max-width: 365px;
  width: 100%;
  .webform-component--name-course-comment,
  .webform-component--id-comment {
    display: none;
  }
  input[type=submit] {
    background: #23b0e8;
    border: none;
    font-size: 13px;
    width: 100%;
  }
}
 .pane-education {
  .pane-title {
    padding-bottom: 30px;
  }
  p {
    text-align: left;
  }
}

  .second-title {
    padding-top: 10px;
    padding-bottom: 45px;
  }

.pane-node {
  h2 {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  ul {
    list-style: none;
    li:before {
      font-family: "Font Awesome 5 Free";
      content: "\f005";
      color: #1B96D1;
      margin-right: 10px;
    }
  }
} 
/* .col-sm-6 {
  @media (min-width: 768px) {
  width: 48%;
    
  }
} */
.hmd {
  @include respond-down(lg) {
    text-align: center;
  }
  ul {
    display: flex;
    list-style: none;
    justify-content: center;
    @include respond-down(lg) {
      display: unset;
    }
    a {
      text-decoration: underline;
      color: black;
    }
  }
}

.cntryimg {
  position: relative;
  margin-bottom: 25px;
  padding-right: 7px;
  padding-left: 7px;
  a {
    color: #fff;
    text-shadow: 0 0 4px #000;
    text-decoration: none;
    @include respond-down(lg) {
      font-size: 16px;
    }
  }
  img {
    width: 100%;
    @include respond-down(md) {
      width: 186px;
      height: 186px;
      margin: 0 auto;
    }
  }
  span {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 5px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    display: table-cell;
    vertical-align: middle;
    font-size: 30px;
    line-height: 40px;
    @include respond-down(lg) {
      top: 13px;
      font-size: 19px;
      line-height: 28px;
    }
    div {
      @include respond-down(lg) {
        top: 0!important;
      }
      @include respond-down(md) {
        padding: 0 57px;
      }
    }
  }
}

.container-720 {
  .pane-content {
    .hotel-small {
      .hotel-img {
        height: 80px;
      }
      h4 {
        width: 100px;
        a {
          font-size: 14px;
          font-family: unset;
        }
      }
    }    
  }
}
.b-services {
  background: url(../images/bg_services_small.png);
  background-size: cover;
  margin: 20px 0;
  .row {
    padding: 10px;
    .icon-block {
      @include respond-down (lg) {
        padding-top: 20px;
      }
    }
  }
  .col-md-4 {
    width: 33.33333333%;
  }
}
.padd-90 {
  padding-bottom: 90px;
  @include respond-down (md) {
    padding-bottom: 30px;
  }
}
.startable {
  border: 1px solid #eeeeee;
  td {
    border: 1px solid #eeeeee;
  }
}

.pane-rendering-schools {
  .container-720 {
    @media  screen and (max-width: 767px) {
      width: 100%;
    } 
    .list-item-entry {
      @media  screen and (max-width: 767px) {
        box-shadow:0 0 11px 0 rgba(0,0,0,0.28);
        margin-bottom: 25px;
      } 
      .hotel-item {
        box-shadow: none;
        height: auto;
        .table-view {
          display: flex;
          margin-bottom: 30px;
          box-shadow:0 0 11px 0 rgba(0,0,0,0.28);
          @media  screen and (max-width: 767px) {
            display: inline;
          }   
          .radius-top {
            width: 465px;
            border-radius: 0;
            overflow: visible;
            @media  screen and (max-width: 767px) {
              width: 100%;
              height: auto
            }
            img {
              //width: 260px;
              padding-top: 10px;
              padding-left: 10px;
              @media  screen and (max-width: 767px) {
              height: auto;
              padding-left: 0;
              }
            }
            .rating-detail {
              font-size: 13px;
              padding: 14px 15px;
              border-radius: 5px;
              background: #E4F7FE;
              display: block;
              margin-left: 10px;
              padding-bottom: 8px;
              i {
                display: inline-block;
                margin-right: 3px;
                width: 16px;
                height: 16px;
                background: url(/sites/all/themes/uwstar/assets/images/detailStar.svg) no-repeat center center;
                background-size: 16px 16px;
              }
            }
          }
          .hotel-middle  {
            padding-top: 0;
            border: none;
            padding: 10px 10px 5px 29px;
            @include respond-down (md) {
              padding: 10px;
            }
            p {
              font-weight: unset;
              color:black;
              font-size: 17px;
              padding: 0;
              margin: 0;
            } 
            .caption {
              font-weight: 700;
              font-size: 26px;
              @include respond-down (md) {
                font-size: 21px;
              }
              .pseh4 {
                height: auto;
                overflow: unset;
                text-decoration: unset;
                line-height: 36px;
                @include respond-down (md) {
                  line-height: 26px;
                }
                a {
                  text-decoration: none;
                }
              }
            }
            .entity-location {
              display: flex;
              margin-bottom: 26px;
              .f-14 {
                text-decoration: underline; 

              }
              &:before {
                content: "";
                display: inline-block;
                height: 25px;
                width: 25px;
                vertical-align: middle;
                background: url(/sites/default/files/location.png) no-repeat;
                background-size: contain;
              }
            }
            a.button-s-2  {
              width: 140px;
              text-align: center;
              color: #fff;
              text-transform: uppercase;
              background-color: #206ab0;
              border-radius: 50px;
              font-size: 16px;
              border: 3px solid transparent;
              padding: 5px;
              margin-top: 28px;
              float: right;
              &:hover {
                transition: .3s;
                color: #206ab0;
                border: 3px solid #206ab0;
                background-color: transparent !important;
              }
              @media  screen and (max-width: 767px) {
              width: 100%;

              }
            }
          }
          .hotel-person {
            color: #f60;
            font-size: 26px;
            font-weight: bolder;
            padding: 0;
            margin-top: 11px; 
          }
        }
      }
    }
  }
}
.all-school-blocks {
  @include respond-down (md) {
    padding: 0 10px;
  }
  .list-item-entry {
    height: auto;
    margin-bottom: 30px;
    .hotel-item {
      width: max-content;
      margin-bottom: 0;
      height: auto;
      position: unset;
      float: unset;
      .title {
        .caption {
          .pseh4 {
            width: 210px;
          }
        }
      }
      .cell-view {
        p {
          padding: 0;
          margin: 0;
        }
        .caption {
          font-size: 16px;
        }
        .entity-location {
          padding-top: 10px;
        }
        .hotel-person {
          padding-top: 10px;
        }
        .entity-type-school {
          width: 220px;
          height: 50px;
          overflow-y: hidden;
        }
      }
      .radius-top {
        width: max-content;
        img {
          width: max-content;
        }
      }
    }
  }
}
.page-rendering-schools-filter-resultshtml {
  .education_find_wrap {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .2);
    margin: 10px 0;
    padding: 10px 10px 10px;
    .education_find_subwrap {
      position: relative;
      display: flex;
      .education_find_img {
        padding: 0 15px 0 0;
        .teaser_recommend {
          background: rgba(252, 29, 29, .8);
          position: absolute;
          top: 0;
          font-size: 12px;
          padding: 10px 17px;
          left: -15px;
          text-transform: uppercase;
          font-weight: 700;
          color: #fff;
        }
      }
      .education_find_text {
        padding: 0 0 0 15px;
        font-size: 16px;
        padding-bottom: 50px;
        .education_find_price {
          color: #f60;
          font-size: 26px;
          font-weight: 700;
        }
        .course_more {
          position: absolute;
          right: 0;
          bottom: 10px;
          a {
            width: 100%;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            background-color: #206ab0;
            border-radius: 50px;
            font-size: 16px;
            border: 3px solid transparent;
            padding: 5px 10px;
            margin-top: 20px;
            font-weight: 600;
            text-decoration: none;
            cursor: pointer;
            &:hover {
              transition: .3s;
              color: #206ab0;
              border: 3px solid #206ab0;
              background-color: transparent !important;
            }
          }
        }
        .education_find_country {
          text-decoration: underline;
          &:before {
            content: "";
            display: inline-block;
            height: 25px;
            width: 25px;
            vertical-align: middle;
            background: url(/sites/default/files/location.png) no-repeat;
            background-size: contain;
          }
        }
        .education_find_title {
          a {
            font-weight: 700;
            font-size: 26px;
            text-decoration: none;
            color: #333;
          }
        }
      }
    }
  }
}
.price {
  width: auto;
  overflow-y: auto;
  _overflow: auto;
  margin: 0 0 1em;
  tbody {
    border: 1px solid #00B1EB;
    td {
      border: 1px solid #00B1EB;
    }
  }
}
.node-type-page-with-cards {

}
.startable {
   width: 100%;
   overflow: scroll;
  overflow-y: auto;
  _overflow: auto;
  margin: 0 0 1em;
}

.school-slide {
  img {
    margin: 0 auto;
  }
}
.page-rendering-schools-filter-resultshtml {
  .container-720 {
    @include respond-down (md) {
        width: 100%;
      }
    .education_find_subwrap {
      @include respond-down (md) {
        display: unset;
      }
      .education_find_img {
        @include respond-down (md) {
          img {
            height: auto;

          }
        }
      }
    }
    
  }
  
}
.trigger {
  display: block;
  font-weight: bold;
  font-size: 14px !important;
  padding: 12px 10px 12px 35px;
  background: #EAFBFF;
  color: #545A5E !important;
  border: 1px solid #C8E3EA;
  border-radius: 2px;
  &:hover {
    text-decoration: none;
  }
}
.trigger-div {
  padding: 0 35px 20px;
}

.page-node-8991  {
  table {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
}
.page-node-8770 {
  .price {
    text-align: left;
    p, ul {
      font-weight: 500;
    }

  }
}
.page-node-9009 {
  .node-9009 {
    img {
      max-width: 95%;
    }
    
  }
}
.page-node-8592 {
  .hotel-img {
    width: 100%;
  }
}
.h2 {
  text-transform: uppercase;
}
.tabs-content {
  .nav-tab-item {
    position: relative;
    &:after {
      background: url(/sites/default/files/arrow_bl.png) no-repeat;
      position: absolute;
      content: '';
      width: 14px;
      height: 9px;
      right: 10px;
      top: 20px;
    }
  }
  .active-mobail {
    &:after {
      transform: rotateX(180deg);
    }
  }

}
.node-page-with-cards-full {
  padding-bottom: 50px;
}

.page-node-8587 {
  ul {
    padding: 0;
  }
}

.icon-text {
  a {
    color: #fff!important;
  }
}
#cboxClose {
  width: 39px;
  height: 25px;
  text-indent: -9999px;
  top: 24px;
  transform: scale(3);
  @include respond-down (md) {
    width: 25px;
    top: 0px;
    transform: scale(1);
  }
}
.icon-title {
  a {
    color: #fff!important;
  }
}
.node-type-page-with-cards .second-title:nth-child(2) {

    padding-bottom: 40px;

}

.school-info-countries {
  .pane-content {
    .second-title {
      padding-bottom: 30px;
    }
  }
}
.page-node-8587 {
  #content {
    .slick-dots {
      margin-left: 0px;
      @include respond-down(md) {
        display: none!important;
      }
    }
    ul {
      margin-left: 35px;
    }   
  }
}
.uwlinkdv {
  .style-2 {
    justify-content: center;
    a{
      text-decoration: underline;
      font-size: 18px;
    }
  }
}
.pane-container-width {
  .second-title {
    margin-top: 10px;
  }
}
.simple-tab.color-1 .tabs-content  {
   
  @include respond-down(lg) {
    .tab-info.active{ 
      display: none;
    }
    .tab-info.active-mobail {
      display: block;
    }
  }
}