.footer-top {
  background-color: #364e67!important;
  .footer-top-info {
    width: 100%;
    padding: 0 27px 0 29px;
    .social-block{
      padding-bottom: 30px;

    }
    @include respond-down (lg) {
      padding:0;
    }
    &:after {
      content: "";
      position: absolute;
      right: 40px;
      width: 93%;
      bottom: 30px;
      border-bottom: 1px solid #c7e9ff;
      @include respond-down (lg) {
        right:0;
        width: 100%;
      }
      @include respond-down (lg) {
        right:0;
        width: 100%;
      }
    }
    .block__content {
      .footer-block {
        display: flex;
        @include respond-down (lg) {
          padding-right:0;
        }
        @include respond-down (md) {
          display: unset;

        }
        .col-lg-6 {
          &:nth-child(1) {
            .contact-block .text:nth-child(1) {
              margin-bottom: 5px;
            }
          }
          &:nth-child(2) {
            display: block;
            text-align: right;
            @include respond-down (md) {
              text-align: unset;
              margin-top: 25px;
            }
          }

        }

        .contact-block {
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          padding-bottom: 0!important;
          
          .contact-block:first-child {
            a {
              color: white;
              font-family: "Open Sans", sans-serif;
              font-size: 29px;
              font-weight: 700;
              line-height: 35px;
              text-transform: uppercase;

            }
          }
          .contact-block:nth-child(2) {
            a {
              color: #00a9e8;
              font-family: "Open Sans", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;

            }
          }
          .contact-block:nth-child(3) {
            a {
              color: white;
              font-family: "Open Sans", sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 35px;
              text-decoration: underline!important;

            }
          }
          .adr {
            border-left-color: #fff;
            border-left-style: solid;
            border-left-width: 3px;
            padding-bottom: 0!important;
            padding-left: 12px;
            margin-left: 4px;
            margin-top: 27px;
            font-family: "Open Sans", sans-serif;
            font-size: 18px!important;
            font-weight: 400;
            line-height: 21px;
          }

          .politics {
            color: white!important;
            font-size: 14px!important;
          }
        }
      }

    }
  }
}
.footer-bottom,
.footer-bottom-contacts {
  background-color: #364e67!important;

}
#footer {
  .region-footer-top,
  .region-footer-bottom,
  .region-footer-bottom-contacts {
    @extend .container;
    @include respond-down (md) {
      padding: 0 15px;
    }
  }
  .region-footer-bottom {
    padding: 0 25px 0 30px;
    position: relative;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    @include respond-down(lg) {
      padding:0;
      position: unset;
    }
    @include respond-down(md) {
      padding:0;
      position: unset;
    }
    &:before {
      content: "";
      position: absolute;
      left: 40px;
      width: 93%;
      border-top: 1px solid #c7e9ff;
      top: -30px;
      @include respond-down(lg) {
        top: 40px;
        left: 51px;
        width: 86%;
      }
      @include respond-down(md) {
        top: 0;
        width: 320px;
        left: calc((100% - 320px)/2);
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: 40px;
      width: 93%;
      border-top: 1px solid #c7e9ff;
      top: 85px;
      @include respond-down(lg) {
        display: none;
      }
    }
  }
  .region-footer-bottom-contacts {
    padding-bottom: 70px;
    @include respond-down(lg) {
      display: none;
    }
    .row {
      padding: 0px 35px;
      .regions-adresess {
        font-family: "Open Sans";
        color: white;
        font-size: 14px;
        font-weight: 400;
        h3 {
          color: #00a9e8;
          font-size: 18px;
          text-transform: capitalize;
        }
      }
    }
  }
  .footer-top {
    background: #1b1b1b;
    padding-top: 80px;
    @include respond-down(lg) {
      padding-top: 64px;
    }
    .block__title {
      color: #00a9e8;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      margin: 0;
      padding: 0 0 15px;
      text-transform: uppercase;
    }
    ul.menu {
      li {
        margin-left: 15px;
        a {
          color: white;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 25px;
          padding: 0;
          &:hover,
          &:focus {
            background: none;
          }
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .footer-block {
    float: left;
    margin-bottom: 70px;
    width: 100%;
  }
  .contact-block {
    &.caption {
      color: #0095d8;
      font-weight: 700;
      font-size: 14px;
      padding-bottom: 15px;
      text-transform: uppercase;
    }
    &.text {
      color: #fff;
      font-size: 14px;
      padding-bottom: 30px;
      text-transform: none;
    }
    a {
      //  color: #fff;
      text-decoration: none;
    }
  }
  .social-block {
    a {
      color: #00a9e8;
      font-size: 40px;
      margin-left: 10px;
    }
  }
  .footer-bottom {
    background: #141414;
    padding: 70px 0 110px;
    width: 100%;
    @include respond-down(lg) {
      padding: 30px 0 18px;
      position: relative;
    }
    @include respond-down(md) {
      padding: 15px 0 18px;
      position: relative;
    }
  }
  .footer-logo {
    display: inline-block;
    float: left;
    @include respond-down(lg) {   
      text-align: center;
      display: unset;
      float: unset;
    }
    .block__content {
      @include respond-down(lg) {
        margin-top: 100px;
      }
      @include respond-down(md) {
        margin-top: 70px;
      }
    }
  }
  .fab{
    width: 40px;
    height: 40px;
    display: inline-block;
    &:before{
      content: none;
    }
    &.fa-facebook{ background: url("../images/fb1-icon.png") no-repeat center center }
    &.fa-vk{background: url("../images/vk1-icon.png") no-repeat center center}
    &.fa-youtube{background: url("../images/yt-icon.png") no-repeat center center}
    &.fa-instagram{background: url("../images/inst1-icon.png") no-repeat center center}
    &.fa-telegram{background: url("../images/tg1-icon.png") no-repeat center center}

  }
  .footer-bottom-menu {
    float: left;
    @include respond-down(lg) {
      position: absolute;
      top: 80px;
      left: calc((100% - 413px)/2);
    }
    @include respond-down(md) {
      position: absolute;
      top: 35px;
      left: calc((100% - 111px)/2);
    }
    .block__content {
      @include respond-down(lg) {
        line-height: 2.5;
      }
      ul {
        margin: 10px 150px;
        padding: 0;
        @include respond-down(lg) {
          margin:0;
          width: 400px;
          display: flex;
          justify-content: space-between;
        }
        @include respond-down(md) {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin: 0;
          width: auto;
        }
        li {
          float: left;
          margin-left: 15px;
          margin-right: 15px;
          position: relative;
          a {
            color: #fff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            padding: 0;
            text-decoration: none;
            text-transform: uppercase;
            &:hover,
            &:focus {
              background: none;
            }
          }
        }
      }

    }
  }
  .footer-copyright {
    float: right;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    position: relative;
    width: 145px;
    padding-top: 12px;
    p {
      padding-bottom: 0;
    }
    @include respond-down(lg) {
      position: unset;
      float: unset;
      text-align: center;
      width: auto;
      margin: 0 auto;
    }
    @include respond-down(md) {
      font-weight: 300;
      line-height: 14px;
    }
  }
  .block.block-menu{
    padding-left: 5px;
    @media (min-width: 1170px) {
      &.col-lg-2 {
        width: 17.5%;
      }
    }
    @include respond-down(lg) {
      .col-lg-2{
        width: 25%;
      }
    }
    @include respond-down(md) {
      padding: 0;
    }
    ul.menu{
      padding-top: 10px;
      li{
        margin-left: 0;
        @include respond-down(md) {
          margin: 0;
        }
        @include respond-down(lg) {
          margin: 5px 0;
        }
        a{
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 25px;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    @include respond-down(lg) {
      margin-left:0;
    }
    @include respond-down(md) {
      margin-left:0;
    }
  }
  #block-menu-menu-high-education-footer-menu{
    margin-left: 75px;
    padding-left: 0;
    @include respond-down(lg) {
      margin-left:0;
    }
  }
  #block-menu-menu-langcourses-footer-menu{
    @media (min-width: 1170px) {
      &.col-lg-2{
        width: 16.5%;
      }
    }
  }
  #block-menu-menu-program-footer-menu{
    @media (min-width: 1170px) {
      &.col-lg-2 {
        width: 20%;
      }
    }
    @include respond-down (lg) {
      float: unset;
      display: inline-block;
    }
  }
  #block-block-53{
    @include respond-down(md) {
      text-align: center;
      padding-top: 95px;
      padding-bottom: 8px;
      display: inline-block;
      width: 100%;
    }
    img {
      height: auto;
      width: 190px;
      @include respond-down(md) {
        width: auto;
      }
    }
  }
}
