body{

}

@font-face {
    font-family: HeliosCondBold;
    src: url(../fonts/HeliosCondBold.ttf);
	src: url(../fonts/HeliosCondBold.eot);
	src: url(../fonts/HeliosCondBold.woff);
	src: url(../fonts/HeliosCondBold.svg);
	src: url(../fonts/HeliosCondBold.woff2);
	src: url(../fonts/HeliosCondBold.otf);
}

@font-face {
    font-family: RobotoBold;
    src: url(../fonts/roboto/Roboto-Bold.ttf);
	src: url(../fonts/roboto/Roboto-Bold.eot);
	src: url(../fonts/roboto/Roboto-Bold.woff);
	src: url(../fonts/roboto/Roboto-Bold.svg);
	src: url(../fonts/roboto/Roboto-Bold.woff2);
	src: url(../fonts/roboto/Roboto-Bold.otf);
}

@font-face {
    font-family: RobotoLight;
    src: url(../fonts/roboto/Roboto-Light.ttf);
	src: url(../fonts/roboto/Roboto-Light.eot);
	src: url(../fonts/roboto/Roboto-Light.woff);
	src: url(../fonts/roboto/Roboto-Light.svg);
	src: url(../fonts/roboto/Roboto-Light.woff2);
	src: url(../fonts/roboto/Roboto-Light.otf);
}

@font-face {
    font-family: OpenSans;
    src: url(../fonts/Open_Sans/OpenSans.ttf);
	src: url(../fonts/Open_Sans/OpenSans-Regular.eot);
	src: url(../fonts/Open_Sans/OpenSans.woff);
	src: url(../fonts/Open_Sans/OpenSans.svg);
	src: url(../fonts/Open_Sans/OpenSans-Regular.woff2);
	src: url(../fonts/Open_Sans/OpenSans.otf);
}

.node-type-page-with-cards {
	.field-name-field-top-content-article {
		ul{
			margin:0;
			padding:0;
			display: flex;
			li{
				display:inline-block;
				width:363px;
				height:253px;
				position:relative;
				margin: 0 10px;
				div{
					font-family: HeliosCondBold;
					position: relative;
					z-index: 1;
					width: 363px;
					height: 253px;
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					font-size: 50px;
					color: #fff;
					padding-right: 12px;
					padding-left: 12px;
					line-height: 1;
				}
				img	{
					position:absolute;
					width:363px;
					height:253px;
					top:0;
				}
			}
			li:first-child{
				margin-left:0;
			}
			li:last-child{
				margin-right:0;
			}
		}
	}
	.container .node.node-page-with-cards {
		.field-name-field-tags-article.field-type-taxonomy-term-reference {
			display:none;
		}
		.field-name-field-razdel{
			display:none;
		}
		.field-name-body{
		/*	font-family: OpenSans;*/
			a{
				color: #0095d9;
			}

		}

	}
	.row.top-row{
		.pane-title{
			display:none;
		}
		ul{
			margin:0;
			padding:0;
			li{
				display:inline-block;
				width:363px;
				height:253px;
				position:relative;
				margin: 0 10px;
				div{
					font-family: HeliosCondBold;
					position: relative;
					z-index: 1;
					width: 363px;
					height: 253px;
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					font-size: 50px;
					color: #fff;
					padding-right: 12px;
					padding-left: 12px;
					line-height: 1;
				}
				img	{
					position:absolute;
					width:363px;
					height:253px;
					top:0;
				}
			}
			li:first-child{
				margin-left:0;
			}
			li:last-child{
				margin-right:0;
			}
		}
	}
	.row.bottom-row{
		h2.pane-title{
			font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
			font-size: 30px;
			text-transform: none;
			font-weight:bold;
		}
		.webform-client-form-4364 {
			width: 100%;
			.form-actions{
				margin: 0;
				padding-left: 15px;
				width: 25%;
				float: left;
				input[type=submit] {
					background: #0095d9;
					border: 2px solid #0095d9;
					height: 50px;
					border-radius: 25px;
					display: inline-block;
					font-size: 14px;
					font-weight: 700;
					padding: 14px 18px;
					position: relative;
					transition: all .35s ease-in-out;
					text-decoration: none;
					width: 100%;
				}
				input[type=submit]:hover {
					background: #fff;
					border: 2px solid #0095d9;
					color: #0095d9;
				}
			}
			.form-item{
				width: 25%;
				float: left;
				padding: 0 15px;
				input[type=text] {
					background: #fff;
					border: 1px solid #0095d9;
					border-radius: 25px;
					color: #777;
					font-size: 14px;
					height: 50px;
					opacity: 1;
					padding: 0 20px 0 44px;

				}
				select {
					background-color: #fff;
					border: 1px solid #0095d9;
					border-radius: 25px;
					color: #777;
					font-size: 14px;
					height: 50px;
					opacity: 1;
					padding: 0 20px 0 44px;
				}
			}
			.form-item.webform-component--imya1{
				padding-left: 0;
			}
		}
		label{
			display:none;
		}

		.pane-node-field-razdel{
			ol{
				width:100%;
				margin:0;
				padding:0;

				li{
					width: 44%;
					float: left;
					margin-right: 68px;
					/* padding-right: 5px; */
					margin-bottom: 25px;
					vertical-align: top;
					color: #2eaf2b;
					font-size: 24px;
					font-family: open sans;
					div{
						color:#222222;
						font-size: 14px;
					}
				}
				li:last-child{
					width: 100%;
				}
			}
			.block_doctor{
				ul{
					margin:0;
					padding:0;
					width: 100%;
					li{
						display:inline-block;
						float:left;
						width: 11%;
						margin-top: 40px;
						img{
							width:100px;
							height:100px;
							border: 1px solid #0095d9;
							border-radius:10px;
							margin-right:20px;
						}
					}
					li:last-child{

						padding:13px 23px;
						background:#0095d9;
						color:#fff;
						font-family: open sans;
						font-size: 14px;
						border-radius:10px;
						width: 89%;
					}
				}
			}
		}
	}
	.uwstar-sidebar-layout {
		.row.middle-row{
			.sidebar-col{
				h2 {
					font-family: RobotoBold;
					font-size: 18px;
					text-transform: none;
				}
				.pane-block-56.fixed {
					padding-top: 170px;
				}
				.pane-block-56{
					margin: 0 0 27px 0;
					li{
						list-style:none;
					}
					h3{
						font-family: RobotoBold;
						font-size: 18px;
						text-transform: none;
						margin: 0 0 27px 0;
				}
					a{
						font-family: RobotoLight;
						color: #000;
						font-size: 13px;
						text-decoration: none;
						display: block;
					}
					a:hover{
						color: #0095d9!important;
					}
				}
				.pane-views.pane-tags-news{
					ul{
						li{
							list-style:none;
						}
					}
				}
			}

			ul{
				li {
				/*	list-style:none;*/
					margin:0;
					a{
						font-family: RobotoLight;
						color: #000;
						font-size: 13px;
						text-decoration:none;
					}
					a:hover{
						color: #0095d9;
						font-size: 13px;
						text-decoration:none;
					}
				}
			}

			.view-footer{
				a{
					color: #0095d9;
					font-size: 13px;
					text-decoration:none;
					background: url(../images/page_title_blue2.jpg) no-repeat right;
					padding-right: 15px;
				}
			}
		}
	}

	.accordion {
		.page_active {
			margin: 0;
			padding: 10px;
			display:none;
		}
		.page_title_blue {
			background: url(../images/page_title_blue.jpg) no-repeat right;
			margin: 0;
			padding-right: 15px;
			color: #0095d9;
			font-weight: bold;
			cursor: pointer;
			display: inline;
		}
	}
	.image_and_text_content{
		border: 0;
		tbody{
			border: 0;
			tr{
				border: 0;
				td{
					border: 0;
				}
			}
		}

	}
	.text_content{
		border: 1px solid;
		tbody{
			border: 1px solid;
			tr{
				border: 1px solid;
				th{
					border: 1px solid;
					font-weight: bold;
				}
				td{
					border: 1px solid;
				}
			}
		}
	}
	.page_title_blue2 {
		display: inline;
		a{
			background: url(../images/page_title_blue2.jpg) no-repeat right;
			padding-right: 15px;
			color: #0095d9;
		}
	}
}
