// .page-node {
//   .uwstar-sidebar-content-col {
//     padding-left: 30px;
//     .pane-node-title {
//       font-size: 30px;
//       font-weight: 400;
//       text-transform: uppercase;
//       text-align: center;
//       line-height: 30px;
//     }
//     .left {
//       float: left;
//       margin: 0 20px 5px 0;
//       width: 35%;
//     }
//     .right {
//       float: right;
//       margin: 0 20px 5px 0;
//       width: 35%;
//     }
//     p {
//       font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//       font-size: 14px;
//       line-height: 1.42857143;
//       color: #333;
//       font-size: 18px;
//     }
//     ul {
//       list-style: none;
//       li {
//         span { 
//           font-size: 18px;
//           line-height: 22px;
//         }
//         &:before {
//           font-family: "Font Awesome 5 Free";
//           content: "\f005";
//           color: #1b96d1;
//           margin-right: 10px;
//         }
//       }
//     }
//   }
// }
// .view-id-front_news {
//   width: 100%;
//   overflow: hidden;
//   .view-content {
//     display: inline-block;
//     left: -15px;
//     position: relative;
//     width: calc(100% + 30px);
//   }
//   .views-row {
//     margin-bottom: 42px;
//     padding: 0 15px;
//   }
// }
.node-page-with-cards-teaser,
.node-article-teaser,
.node-news-teaser {
  img {
    height: auto;
    max-width: 100%;
  }
  .like-h4--small {
    a {
      color: #333;
      line-height: 18px;
      text-decoration: none;
    }
  }
  .tour-info-line {
    margin-bottom: 10px;
  }
  .tour-info {
    font-size: 12px;
    padding-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
  .s_news-text {
    color: #333;
    font-weight: 400;
    font-size: 13px;
    height: 130px;
    line-height: 18px;
    margin-bottom: 23px;
    overflow: hidden;
    a {
      color: #333;
    }
  }
}
.view-id-news {
  .views-row {
    margin-bottom: 70px;
    padding-left: 30px;
  }
  .image-container {
    min-height: 140px;
  }
  .button-s-2 {
    display: none;
  }
}
.node-news-full {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.28em;
  .content {
    ul {
      padding-left: 18px;
    }
  }
}
.pane-block-50 {
  margin-top: 30px;
}
.page-node-4129 {
  .pane-node {
    p {
      color: #0d0d0d;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      padding-bottom: 22px;  
    }  
  }
}