body.page-contacts {
  .container {
    margin: 0 auto;
  }
  .region-content {
    .contact-info-value {
      a {
        display: block;
        padding-top: 10px;
        .phone {
          background: url(../images/phone_ico.png) left top no-repeat;
          padding-left: 35px;
          margin-top: 12px;
          margin-left: 2px;
        }
        .life {
          background: url(../images/life_ico.png) left top no-repeat;
          padding-left: 37px;
          margin-top: 10px;
          margin-left: 2px;
          padding-top: 2px;
        }
        .kievstar {
          background: url(../images/kievstar_ico.png) left top no-repeat;
          padding-left: 37px;
          margin-top: 0px;
          margin-left: 2px;
          padding-top: 2px;
        }
        .vodafone {
          background: url(../images/vodafone_ico.png) left top no-repeat;
          padding-left: 37px;
          margin-top: 0px;
          margin-left: 2px;
          padding-top: 2px;
        }
      }
    }
    span  {
      font-size: 18px;
    }
    .pane-2 {
      .bottom_part {
        display: inline-block;
        width: 100%;
        padding-top: 26px;
        padding-bottom: 41px;
        .long_title {
          color: #333;
          font-size: 20px;
          font-weight: 400;
          line-height: 21px;
          padding-bottom: 43px;
        }
        .display-flex {
          display: flex;
          justify-content: space-between;
          span {
            font-size: 14px;
            font-weight: bold;
          }
          .bottom_title {
            color: #333;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            text-transform: uppercase;
            padding-bottom: 14px;
          }
          .lviv {
            .bottom_text {
              font-size: 14px;
              .phone {
                background: url(../images/phone_ico.png) left top no-repeat;
                padding-left: 35px;
                margin-top: 12px;
                margin-left: 2px;
              }
            }
          }
          .kharkov {
            .bottom_text {
              font-size: 14px;
              .phone {
                background: url(../images/phone_ico.png) left top no-repeat;
                padding-left: 35px;
                margin-top: 12px;
                margin-left: 2px;
              }
              .life {
                background: url(../images/life_ico.png) left top no-repeat;
                padding-left: 37px;
                margin-top: 10px;
                margin-left: 2px;
                padding-top: 2px;
              }
              .vodafone {
                background: url(../images/vodafone_ico.png) left top no-repeat;
                padding-left: 37px;
                margin-top: 0px;
                margin-left: 2px;
                padding-top: 2px;
              }
            }
          }
          .ivano-frankovsk {
            .bottom_text {
              font-size: 14px;
              .worktime {
                padding-top: 13px;
              }
              .phone {
                background: url(../images/phone_ico.png) left top no-repeat;
                padding-left: 35px;
                margin-top: 12px;
                margin-left: 2px;
              }
              .vodafone {
                background: url(../images/vodafone_ico.png) left top no-repeat;
                padding-left: 37px;
                margin-top: 0px;
                margin-left: 2px;
                padding-top: 2px;
              }
            }
          }
        }
        .help-contact {
          p {
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 10px;
          }
          .c-button {
            background: #23b0e8;
            text-align: center;
          }
        }
      }
    }
  }
}
.wordwide {
  padding-top: 77px;
  padding-bottom:40px;
  .contact-info {
    margin-bottom: 60px;
    .contact-line {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 33px;
      text-align: left;
      img {
        margin-right: 20px;
        vertical-align: middle;
      }
    }
  }
  .like-h4, .like-h4--small {
      font-size: 16px!important;
      color: #333!important;
      text-align: left;
  }
  .like-h4 {
      margin-bottom: -4px;
      overflow: hidden;
      text-transform: uppercase!important;
  }
  .fl {
      float: left;
  }
  .h50 {
      height: 50px;
  }
  .color-dark-2 {
      color: #222;
  }
  a.color-dark-2 {
    color: #23b0e8;
  }
  .contact-line .fa {
      color: #1B96D1;
      margin-right: 20px;
  }
}
.contact-info {
  img {
    margin-right: 20px;
    vertical-align: middle;
  }
  .color-grey-3 {
    color: #bebebe;
  }
  #email-region {
    .contact-line {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
      text-align: left;
      span {
        font-size: 14px;
        display: inline-block;
        width: 270px;
      }
    }
  }
}
#social-contacts {
  .fab {
    margin-left: 10px;
    width: 40px;
    height: 40px;
    display: inline-block;
    &:before{
      content: none;
    }
    &.fa-facebook{ background: url("../images/fb1-icon.png") no-repeat center center }
    &.fa-vk{background: url("../images/vk1-icon.png") no-repeat center center}
    &.fa-youtube{background: url("../images/yt-icon.png") no-repeat center center}
    &.fa-instagram{background: url("../images/inst1-icon.png") no-repeat center center}
    &.fa-telegram{background: url("../images/tg1-icon.png") no-repeat center center}

  }
}
#address-region {
  font-size: 14px;
}
.hotel-img {
  margin-right: 15px;
}