.node-type-offers {
	h1 {
		text-transform: none;
    text-align: left;
	}
	.left {
		float: left;
		margin-right: 30px;
	}
	.col-xs-12.col-sm-12.col-md-8.col-md-push-4{
		width: 73.66667%;
		left: 26.33333%;
		.pane-node-title {
			.pane-title{
				display:none;
			}
			.pane-content{
				font-weight: bold;
				font-family: "Open Sans", sans-serif;
				font-size: 24px;
				margin: 50px 0 30px 0;
				
			}
		}
		.pane-node-field-price-special{
			.pane-title{
				display:none;
			}
			.pane-content{
				font-weight: bold;
				font-family: "Open Sans", sans-serif;
				font-size: 28px;
				color: #0095d9	
				 
			}
			p{
				padding:0;
			}	
		}
		.pane-node-field-long-special1{
			.pane-title{
				display:none;
			}
			.pane-content{
				color: #000;
				font-family: "Open Sans", sans-serif;
				font-size: 22px;
			}	
		}
		.pane-node-body{
			.pane-title{
				display:none;
			} 
		}
		.pane-node-field-image-special{
			.pane-title{
				display:none;
			} 
		}
	}
	.uwstar-sidebar-content-col{
		padding-left:0;
		width: 74.3%;
		left: 0;
		float: right;
	}
	.sidebar-col{	 
		/*padding-right: 25px;
		width: 26.33333%;
		right: 73.66667%;*/
		float: left;
		left: 0;
		width: 23.51%;
		.pane-webform-client-block-114{
			.form-item{
				margin-bottom: 9px;
			}

			.webform-component--education-spec-name,
			.webform-component--education-spec-link{
				display: none;
			}
			.pane-title{
				display:none;
			}
			.webform-component-checkboxes{
				.form-type-checkbox{
					input[type="checkbox"]{
					  vertical-align: top;
					  margin: 0 3px 0 0;
					  width: 17px;
					  height: 17px;
					}
					input[type="checkbox"] + label {
					  cursor: pointer;
					}
					input[type="checkbox"]:not(checked) {
					  position: absolute;
					  opacity: 0;
					}
					input[type="checkbox"] + label {
					  position: relative; /* будем позиционировать псевдочекбокс относительно label */
					  padding: 0 0 0 37px; /* оставляем слева от label место под псевдочекбокс */
					  margin: 0;
					  color: black;
					  font-weight: 400;
					}
					input[type="checkbox"]:not(checked) + label:before {
					  content: '';
					  position: absolute;
					  top: 2px;
					  left: 10px;
					  width: 17px;
					  height: 17px;
					  border: 1px solid silver;
					  border-radius: 3px;
					}
					input[type="checkbox"]:checked + label:after {
					 	content: '';
					 	position: absolute;
					 	top: 5px;
					 	left: 13px;
					 	width: 11px;
					 	height: 11px;
					 	background:silver;
					 	border-radius: 3px;
					}
				}
			}
			.form-text{
					background-color: #fff;
					border-radius: 25px;
					//border: 1px solid #23b0e8;
					border: 1px solid #0095d9;
					color: #777;
					font-size: 14px;
					height: 50px;
					outline: none;
					padding: 0 0 0 36px;
					transition: all .3s ease-in;
					width: 100%;
					color: black;
					font-family: "Open Sans", sans-serif;font-size: 14px;font-weight: 400;
					:placeholder {color: black;font-family: "Open Sans", sans-serif;font-size: 14px;font-weight: 400;}
			}	
			.form-select{
				background-color: #fff;
				border-radius: 25px;
				//border: 1px solid #23b0e8;
				border: 1px solid #0095d9;
				color: #777;
				font-size: 14px;
				height: 50px;
				outline: none;
				padding: 0 0 0 36px;
				transition: all .3s ease-in;
				width: 100%;
				color: black;font-family: "Open Sans", sans-serif;font-size: 14px;font-weight: 400;
				background-image: url('../images/select-burger.png');
				background-position: right 20px center;
				background-repeat: no-repeat;
				appearance: none!important;	
			}
			.form-select::-ms-expand {
				display: none;
			}
			.form-submit{
				border-radius: 25px;
				//background-color: #23b0e8;
				background-color: #0095d9;
				//border: 2px solid #23b0e8;
				border: 2px solid #0095d9;
				outline: none;
				color: #fff;
				height: 50px;
				width: 100%;
				font-family: "Open Sans", sans-serif;
				font-size: 14px;
				font-weight: 700;
			}
			.form-submit:hover {
				background: none;
				//color: #23b0e8;
				color: #0095d9;
			}		
		}	
		.webform-component-checkboxes.webform-component--ya-ne-robot {
			margin-top: 17px;
			margin-bottom: 20px;
			label{
				display:none;
			}
			.form-checkboxes{
				label{
					display:block;
				}
			}
		}
	}
	
	.field-name-field-image-special{
		img{
			max-width: 100%;
			height: auto;
			display: block;
			margin: 0 auto;
		}
	}

	.pane-node-title{
		font-size: 24px;
		color: #000000;
		font-weight: 700;
		margin-top: 25px;
	}

	.field-name-field-price-special{
		margin-top: 14px;
		color: #0095d9;
		font-size: 30px;
		font-weight: 700;
		p{
			margin: 0;
			padding: 0;
		}
	}
	
	.field-name-field-long-special1{
		margin-top: -4px;
		color: #000000;
		font-size: 18px;
		font-weight: 300;
		p{
			margin: 0;
			padding: 0;
		}
	}

	.field-name-body{
		margin-top: 33px;
		font-size: 18px;
		line-height: 24px;
		font-weight: 300;
		color: #000000;
	}
}
.b-consult-form {
	.node-4462 {
		h2 {
			display: none;
		}
		.second-title {
			font-size: 32px;
			line-height: 45px;
			text-align: center;
			font-weight: 700;
			text-transform: uppercase;
			color: white;
		}
	}
}
.page-node-8963{ //нода выбора направления спецпредложений
  .s_news-entry.news_item.style-2 .tour-info-line{
    padding-bottom: 50px; 
  }
}
.page-offers, .page-news {
	.region-content {
		padding-top: 0;
	}
	.node-offers-teaser {
		box-shadow: none;
		padding-bottom: 50px;
	}
	.view-header{
		h1{
			margin-top: 0;
			margin-bottom: 35px;
			padding-left: 15px;
		}
	}
	.s_news-text{
		color: #333;
		font-weight: 400;
		font-size: 13px;
		height: 130px;
		line-height: 18px;
		margin-bottom: 15px;
		overflow: hidden;
	}
	.tour-info.fl{
		font-size: 12px;
		margin-bottom: 10px;
		line-height: 1;
	}
	.like-h4--small{
		font-weight: 700;
		height: 45px;
		margin: 15px 0;
		overflow: hidden;
	}
}