.view-specials-sidebar.view-id-specials_sidebar.view-display-id-block_8{
	.view-header{
		display:none;
	}
}
.school-info-countries {
	.big-images-row{
		 .col-md-4{
			a{
				position:relative;
				text-decoration:none;
				img {
					/*position: absolute;*/
				}
				span {
					/*position: relative;
					width: 363px;
					height: 253px;
					display: table-cell;
					vertical-align: middle;*/
					z-index: 1;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction:row;
					align-items:center;
					justify-content:center;
					text-align: center;
					font-size: 50px;
					color: #fff;
					font-family: HeliosCondBold;
				}
			}
			a:hover{
				text-decoration:none;
			}
		 }

	}

}
.high_education_results{
	.pane-title{
		display:none;
	}
	.results_education{
		.result_top_form{
			right: 66.66667%;
			width: 25%;
			float: left;
			padding-right: 15px;
			padding-left: 15px;
			.result_top_form_block{
				background: #E2EDF3;
				padding: 18px 20px;
				width: 100%;
				.sidebar-title{
					font-size: 22px;
					margin-bottom: 27px;
					font-weight: 700;
					text-transform: uppercase;
				}
				form{
					.form-actions{
						margin:35px 0 20px 0;
					/*	padding-left: 15px;
						padding-right: 15px;*/
					/*	width: 25%;
						float: left;*/
						input[type=submit] {
							background: #23b0e8;
							border: 2px solid #23b0e8;
							height: 50px;
							border-radius: 25px;
							display: inline-block;
							font-size: 14px;
							font-weight: 700;
							padding: 14px 18px;
							position: relative;
							transition: all .35s ease-in-out;
							text-decoration: none;
							width: 100%;
						}
						input[type=submit]:hover {
							background: #fff;
							border: 2px solid #23b0e8;
							color: #23b0e8;
						}
					}
					#form-item-high-education-course{
						margin-top: 35px;
					}
					#form-item-high-education-specialties{
						margin-top: 35px;
					}
					.form-item{
					/*	width: 25%;
						float: left;*/
						margin-bottom: 15px;
						margin-top: 35px;
						input[type=text] {
							background: #fff;
							border:0 solid #23b0e8;
							border-radius: 25px;
							color: #777;
							font-size: 14px;
							height: 50px;
							opacity: 1;
							padding: 0 20px 0 44px;

						}
						select {
							background-color: #fff;
							border:0 solid #23b0e8;
							border-radius: 25px;
							color: #777;
							font-size: 14px;
							height: 50px;
							opacity: 1;
							padding: 0 20px 0 20px;
						}
					}
					.form-item.webform-component--imya1{
						padding-left: 0;
					}
				}
			}

			label{
				display:none;
			}
		}
		.education_find_right{
			left: 33.33333%;
			width: 75%;
			float: left;
			padding-right: 15px;
			padding-left: 15px;

			.education_sorts{
				float: left;
				.list_schools_count {
					font-size: 14px;
					font-weight: 400;
					display:none;
				}
				.list_schools_sort {
					display: inline-block;
					width: auto;
					min-width: 170px;
					margin-right: 30px;
					color: #999;
					position: relative;
					width: 320px;
					height: 50px;
					ul{
						float: right;
						height: 23px;
						overflow: hidden;
						position: absolute;
						z-index: 3;
						right: 0;
						top: 0;
						background: #fff;
						padding: 0;
						width:168px;
						li{
						/*	display:inline-block;*/
						/*	padding-right: 10px;*/
							list-style: none;
							a{
								color: #999;
								padding:0 10px;
								display:block;
								min-width:100px;
								text-decoration:none;
							}
							a:hover{
								color: #fff;
								background: #0095D9;
							}
						}
						li.first{
							background:url(../images/select_search_form_sort.jpg) no-repeat right;
							a:hover{
								color: #999;
								background:none;
							}
						}
						li.active{
							a{
								color: #fff;
								background: #0095D9;
							}
						}
					}
					ul:hover{
						height:95px;
					/*	border:1px solid #0095D9;*/
						li.first{
							border-left:0px solid #0095D9;
							border-right:0px solid #0095D9;
							border-top:0px solid #0095D9;
							border-bottom:1px solid #0095D9;
						}
						li{
							border-left:1px solid #0095D9;
							border-right:1px solid #0095D9;
							border-top:0px solid #0095D9;
							border-bottom:0px solid #0095D9;
						}
						li.last{
							border-left:1px solid #0095D9;
							border-right:1px solid #0095D9;
							border-bottom:1px solid #0095D9;
							border-top:0px solid #0095D9;
						}

					}
				}

			}
				tbody {
					border-top:0 solid #ccc;
				}
				td {
					padding:0 15px 0 15px;
					line-height: 1.42857;
					text-align: left;
					vertical-align: top;
					border-top:0 solid #ddd;
				}
			.education_find_wrap{

				.course_more{
					a{
						background: #23b0e8;
						height: 38px;
						border-radius: 5px;
						display: inline-block;
						font-size: 13px;
						font-weight: 600;
						padding: 10px 20px;
						position: relative;
						transition: all .35s ease-in-out;
						text-decoration: none;
						color:#fff;
						margin-bottom:20px;
					}
				}
				.education_find_img{
					position:relative;
					height:157px;
					width:262px;
					.teaser_recommend{
						position:absolute;
						z-index:2;
						top: 0;
						left: 0;
						background: rgba(252,29,29,.8);
						font-size: 12px;
						padding: 10px 17px;
						color: #FFF;
						font-weight: 700;
						text-align: center;
						text-transform: uppercase;
						border-radius: 5px 0 0 0;
					}
					a{
						img{
							position:absolute;
							z-index:1;
							display:block;
							border-radius: 5px 5px 0 0;
							width: 100%;
							height: 157px;
							/*width: 262px;*/
						}
					}
				}
				.education_find_text{
					padding: 27px 20px 25px;
					display: block;
					width: 100%;
				/*	height: 157px;*/
					text-align: left;
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
					vertical-align: middle;
					.education_find_title{
						height: 64px;
						overflow: hidden;

						a{
							text-transform: uppercase;
							font-weight: 700;
						}
					}
					.education_find_country{
						font-weight: 700;
						font-size: 12px;
						color: #777;
						padding: 10px 0;
					}
					.education_find_price {
						font-size: 16px;
						color: #23b0e8;
						font-weight: 700;
						text-transform: none;
						padding-bottom: 20px;
					}
				}
				img{
					display:none;
				}
			}
				.education_pager{
					li{
						display:inline-block;
						padding-right: 10px;
						a{
							color: #999;
						}
						.active{
							background: #23b0e8;
							border-radius: 5px;
							font-size: 13px;
							font-weight: 600;
							padding: 5px 10px;
							position: relative;
							color: #fff;
						}
					}
				/*	li.first{
						display:block;
					}*/
				}
		}
	}
}


.bg-blue {
    background: #0095D9;
}
.right-sidebar{
	.detail-block {
		.title_wrap {
			h1 {
				text-transform: none;
				text-align: left;
				margin-top: 0px;
				margin-bottom: 10px;
				font-size: 24px;
			}
		}
		.rating-main {
			margin-bottom: 20px;
			display: inline-block;
			cursor: pointer;
			&:hover {
				.rating-detail {
					display: block;
					transition: all .35s ease-out;
					position: absolute;
				}
			}
			i {
				display: inline-block;
		    width: 26px;
		    height: 26px;
		    background: url(/sites/all/themes/uwstar/assets/images/falseStar.svg) no-repeat center center;
		    background-size: 26px 26px;
		    margin: 0 3px;
			}
			i.active {
				background: url(/sites/all/themes/uwstar/assets/images/trueStar.svg) no-repeat center center;
			}
			span {
				color: #27aee6;
				font-family: 'OpenSans';
				font-size: 15px;
				font-weight: 400;
				line-height: 29px;
				text-decoration: underline;
				padding: 0 0 0 5px;
		    vertical-align: super;
			}
			.rating-detail {
				font-size: 13px;
		    padding: 10px 15px;
		    border-radius: 5px;
		    background: #E4F7FE;
		    width: 320px;
		    display: none;
		    tbody {
		    	border: none;
		    }
				td {
			    text-align: left;
			    padding: 4px 0;
			    border: none;
				}
				td:last-child {
			    text-align: right;
			    white-space: nowrap;
			    border: none;
				}
				i {
			    display: inline-block;
			    margin-right: 3px;
			    width: 16px;
			    height: 16px;
			    background: url(/sites/all/themes/uwstar/assets/images/detailStar.svg) no-repeat center center;
			    background-size: 16px 16px;
				}
			}
		}
		.school_after_title {
			margin-bottom: 100px;
			@include respond-down(lg) {
				margin-bottom: 60px;
			}
			@include respond-down(md) {
				margin-bottom: 30px;
			}
		}
		.school_after_title_program{
			text-align: left;
			color: #364e67;
			font-weight: 400;
			text-transform: none;
			font-size: 18px;
			line-height: 29px;
			font-family: 'OpenSans';
			span{
				text-align: left;
				margin-left: 3px;
				color: #060606;
				font-weight: 600;
				text-transform: none;
				font-size: 18px;
			}
		}
		.school_city_wrap{
			text-align: left;
			color: #364e67;
			font-weight: 400;
			text-transform: none;
			font-size: 18px;
			line-height: 29px;
			font-family: 'OpenSans';
			.school_city{
				margin-left: 3px;
				font-weight: 600;
				color: #060606;
				a{
					text-align: left;
					color: #060606;
					font-size: 18px;
					margin-left: 3px;
					font-weight: 600;
				}

			}
		}
	}
	.institution-form {
		margin-bottom: 30px;
	}
	.sidebar {
		padding: 18px 20px;
	}
	.bg-white {
		background: #fff;
	}
	.sidebar-title {
		font-size: 22px;
		margin-bottom: 27px;
		font-weight: 700;
		text-transform: uppercase;
	}
	form{
		div {
			.form-item {
				margin-bottom: 15px;
				background: #fff;
				border: 1px solid #fff;
				margin: 20px 0;
				border-radius: 25px;
				position: relative;
				height: 100%;
				width: 100%;
				display: block;
				select {
					background: url(../images/select_arrows.png) right 10px center no-repeat;
					border: 1px solid #b9b9b9;
					border-radius: 25px;
					color: #777;
					opacity: 1;
					font-size: 14px;
					padding: 0 20px;
					display: block;
					height: 50px;
					cursor: pointer;
					width: 100%;
					font-weight: 400;
					outline: 0;
					transition: all .3s ease-in;
					-moz-transition: all .3s ease-in;
					-o-transition: all .3s ease-in;
					-webkit-transition: all .3s ease-in;
					-ms-transition: all .3s ease-in;
					font-family: 'Source Sans Pro',sans-serif;
					background: 0 0;
				}
			}
			input{
				background: #23b0e8;
				border: 2px solid #23b0e8;
				color: #fff;
				font-weight: 700;
				font-size: 14px;
				text-transform: none;
				height: 50px;
				margin: 20px 0;
				width: 100%;
				position: relative;
				display: inline-block;
				border-radius: 25px;
				padding: 14px 18px;
				text-align: center;
			}
			input:hover{
				color: #23b0e8;
				background:#fff;
				border: 2px solid #23b0e8;
			}
		}
	}
}

.accordion.style-2{
	.acc-panel {
		margin-bottom: 17px;
		.acc-title {
			background: #23b0e8;
			color: #fff;
			position: relative;
			font-size: 14px!important;
			font-weight: 700;
			padding: 14px 45px 14px 70px;
			text-transform: uppercase;
			cursor: pointer;
			border-radius: 5px;
			@include respond-down (md) {
				padding: 10px 10px 10px 25px;
    		line-height: 16px!important;
			}
			.fa-angle-down:before {
				content: "\f107";
				position: absolute;
				top: 36%;
				left: 2%;
			}
			&.active {
				.fa-angle-down:before {
					content: "\f106";
				}
			}
		}
		.acc-body {
			display: none;
			font-size: 14px!important;
			line-height: 16px;
			padding: 30px 30px 10px 50px;
			background: #f8f8f8;
			font-weight: 400;
			font-family: 'Open Sans',sans-serif!important;
			color: #000;
			@include respond-down (md) {
				padding: 5px;
			}
		}
	}
}
