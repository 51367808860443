.node-page-full {
	padding: 70px 0;
}
body.node-type-page {
	.field-name-body {
		color: black;
		font-size: 14px;
		font-weight: 300;
		line-height: 18px;
		p {
			padding-bottom: 18px;
		}
		ul {
			&.school_exchange_programs {
				margin: 0;
				padding:0;
				list-style: none;
				font-size:0;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin-top: 40px;
				li {
					padding:0;
					text-align: center;
					display: inline-block;
			    max-width: 33%;
			    padding:0 18px;
			    padding-bottom: 22px;
					a {
						color: black;
						font-size: 17px;
						font-weight: 300;
						line-height: 20px;
						img {
							display: block;
							margin: 0 auto;
						}
					}
				}
			}
		}
	}
  .node-4129 {
    ul {
      li {
        font-size: 18px;
        font-weight: 300;
        line-height: 25px;
      }
    }
  }
}