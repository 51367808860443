.front {
  .panel-pane {
    display: inline-block;
    float: left;
    width: 100%;
  }
}
.pane-container-width {
  .pane-title {
    @extend .container;
  }
  .pane-content {
    @extend .container;
  }
}

.advantages-pane {
  .pane-content {
    .row {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.front-country-pane {
  .pane-title {
    font-size: 30px;
    font-weight: 400;
    text-transform: none;
  }
  .top {
    margin-bottom: 40px;
    img {
      margin-bottom: 25px;
      width: 100%;
    }
    @media (max-width: 991px) {
      display:flex;
      flex-wrap:wrap;
      justify-content: center;
      img {
        width: auto;
      }
      .col-md-4 {
        padding:0 10px;
        padding-bottom: 20px;
      }
    }
  }
  .first-level,
  .second-level {
    a {
      color: #000;
      font-weight: 400;
      position: relative;
      text-decoration: none;
      &:after {
        content: '';
        border-top: 3px solid transparent;
        border-left: 4px solid #639cd3;
        border-bottom: 3px solid transparent;
        height: 0;
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translate(0, -50%);
        width: 0;
      }
    }
  }
  .first-level {
    margin-top: 20px;
    a {
      font-size: 18px;
    }
  }
  .second-level {
    a {
      font-size: 14px;
      font-style: italic;
    }
  }
  .bottom {
    position: relative;
    &.hide-content {
      height: 270px;
      overflow: hidden;
      &:before {
        background: url(../images/hide_content_bg.png) center no-repeat;
        background-size: cover;
        content: '';
        height: 44px;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
    h2 {
      font-size: 18px;
    }
  }
  .show-more-container {
    text-align: center;
    span {
      background: url(../images/more_arrow_down.png) center bottom no-repeat;
      color: #0095d8;
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      padding: 20px 0;
      position: relative;
    }
  }
}

.pane-uwstar-special-front-panel-pane-1 {
  .pane-title {
    padding-bottom: 0;
  }
}

.front-price-pane {
  background: url(../images/bg_services_small.png) center no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  .icon-block {
    img {
      margin-bottom: 40px;
    }
  }
  .pane-content {
     @media (max-width: 1199px) {
      .row:nth-child(2) {
        display: flex;
        flex-wrap: wrap; 
        & > div {
          padding-bottom: 10px;
        }
      }
    }
  }
}

.front-result-guarantee {
  .col-md-4 {
    padding-right: 15px;
  }
  .col-md-8 {
    padding-left: 15px;
  }
}
.b-steps {
  .row {
    display: flex;
    flex-wrap: wrap;
    .result-block {
      width: 50%;
      @include respond-down (lg) {
        width: 100%;
      }
      .icon-title {
        text-align: left;
        padding-left: 56px;
        padding-bottom: 5px;
        padding-top: 20px;
      }
      .icon-img {
        float: left;
      }
      .icon-text {
        font-size: 16px;
        line-height: 20px;
        margin-left: 56px;
        text-align: left;
        padding-right: 30px;
      }
    }
  }
}

.pane-reviews-front-panel-pane-1,
.pane-reviews-front-panel-pane-2,
.pane-reviews-front-panel-pane-3,
.pane-reviews-high-middle-langcourses,
.pane-reviews-front{
  background-color: #00a9e8;
  background-size: cover;
  padding-bottom: 121px;
  position: relative;
  @include respond-down (md) {
    padding-bottom: 80px;
  }
  .slick__slide {
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      height: 40px;
      width: 40px;
      vertical-align: middle;
      background: url(/sites/default/files/user_blue.png) no-repeat;
      background-size: contain;
      position: absolute;
      top: 37px;
      left: 38px;
      @include respond-down (lg) {
        left: unset;
        top: 25px;
        right: 25px;
      }
      @include respond-down (md) {
        top: 15px;
        right: unset;
        left: calc(50% - 15px);
        position: relative;
      }
    }
    .views-field-field-review-name-age {
      position: relative;
      .field-content {
        &:after {
          content: "";
          display: inline-block;
          height: 2px;
          width: 65px;
          vertical-align: middle;
          background: url(/sites/default/files/name_line.png) no-repeat;
          background-size: contain;
          position: absolute;
          bottom: 0;
          left: 0;
          @include respond-down(md) {
            bottom: unset;
            top: 8px;
            left: calc(50% - 31px);
          }

        }
      }
    }
  }    
}

.b-partners {
  padding-bottom: 90px;
  img {
    padding-bottom: 10px;
  }
}
.front {
  .pane-custom.pane-6 {
    .col-md-12 .col-md-12 {
      @media (max-width: 1199px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
       @media (max-width: 639px) {
        .text-center {
          padding:0 5px;
          padding-bottom: 10px;
        }
       }
    }
  }
}

.pane-startravel-startravel-recommended-swiper {
  background: #e1edf3;
  padding-bottom: 107px;
}

.pane-blog-front-panel-pane-1 {
  background: url(../images/bg_blog.png) center no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  @include respond-down (lg) {
    .views-row {
      padding:0 5px;
    }
  }
  .view-footer {
    display: none;
  }
}
.view-id-blog_front {
  .button-s-2 {
    display: none;
  }
}
.front-slider {
  padding-top: 55px;
  padding-bottom: 25px;
  @include respond-down(md) {
    padding-top: 44px;
    //padding-bottom: 0;
  }
  .slick__slide {
    img {
      margin: 0 auto;
    }
  }
}
