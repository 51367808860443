@include respond-down(md) {
  body .region-before-footer-webform .content #webform-ajax-wrapper-4360 {
    background: unset; 
    border-radius: unset;
    box-shadow: unset;
    .webform-client-form {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 10px;
      .panel-default {
        &:after {
          display: none;
        }
      }
      .form-wrapper {
        margin-left: 0;
        margin-right: 0;
        .form-control {
          width: 100%;
        }
      }
      .webform-component--vopros {
        margin-left: 0;
        input {
          width: 100%; 
        }
      }
      .webform-component--checkbox{
        margin-left: 0;
        .form-type-checkbox {
          margin-right:0;
          float: left;
        }
      }
      .form-actions {
        float: unset;
        display: flex;
        width: 285px;
        margin: 0 auto;
        margin-top: 170px;
        #edit-webform-ajax-submit-4360 {
          margin: 0;
        }
      }
    } 
  }
  .pane-our .pane-content .icon-block {
    padding: 0;
  }
  .pane-educ {
    .educ {
      line-height: 25px;
      p:first-child {
        margin-left: 0!important;
        font-size: 23px;
      }
      p:nth-child(2) {
        margin-left: 0!important;
        font-size: 23px;
      }
    }
  }
  body .pane-boris {
    .img-cont {
      text-align: center!important;
    }
    .img-text {
      position: unset;
      margin-right: 0;
      margin-top: 30px;
      p {
        padding-bottom: 0;
      }
    }
  }
  .pane-reviews {
    .pane-content {
      padding: 0!important;
      .view-reviews-front{
        border-radius: 0;
        .slide__content {
          padding: 0;
          .views-field-field-review-name-age {
            //float: unset;
          }
          .views-field-body {
            //padding: 0 25px;
          }

        }
      }  
    }
  }
}