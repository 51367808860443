.pane-blog-front-panel-pane-1  .pane-content .slide{
  margin: 20px;
  box-shadow: 0 5px 20px 2.7px rgba(26, 26, 26, 0.44);
}
.d-none{
  display: none !important;
} 
.region-content,.article-education-language-content .list-item-entry{
  background: #fff;
}
.node-type-page-with-cards{
  .breadcrumb+div{ 
    padding-top: 0;
  }
  .second-title{
    padding-bottom: 20px;
  }
}
//@media (min-width: 1170px) {
  .d-flex {
    display: flex !important;
    flex-flow: row wrap;
  }
  .justify-content-around {
    justify-content: space-around !important;
  }
  .justify-content-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
//}
@include respond-down(lg) {
  .d-lg-none{
    display: none !important;
  }
  .d-lg-block{
    display: block !important;
  }
  .pane-blog-front-panel-pane-1  .pane-content .slide{
    margin: 0;
  }
  .header-top {
    .header-logo-link { 
      width: 25%;
    }
  }
  .header-bottom {
    height: 50px;
  }
  .view-blog-front {
    .slick__slide {
      .slide__content {
        position: relative;
        img {
          width: 260px;
          height: 330px;
          margin: 0 auto;

        }
        .views-field-nothing {
          width: 260px;
          height: 115px;
          top: 215px;
          margin: 0 auto;
          left: 50px;
          .opas-text {
            margin-top: 10px;
            line-height: 14px;
            .title {
              font-size: 15px;
              margin-bottom: 6px;
            }
            .body {
              font-size: 10px;
            }
          }
        }
        .views-field-view-node {
          width: 260px;
          margin: 0 auto;
          .field-content {
            margin-top: 7px;
            margin-right: 10px;
            float: right;
          }
        }
      }
    }
    .slick__slide:nth-child(even) {
      .slide__content {
        img {
          // margin-right: 5px;
          margin-left: 5px;
        }
        .views-field-nothing {
          left: 0;
          margin-right: 5px;
          margin-left: 5px;
        }
        .views-field-view-node {
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
    .slick__slide:nth-child(odd) {
      .slide__content {
        img {
              margin-left: 5px;
          margin-right: 5px;

        }
        .views-field-nothing {
          right: 0;
          margin-right: 5px;

        }
        .views-field-view-node {
          margin-right: 5px;

        }
      }
    }
    /* .slick-prev {
      left: calc((100% - 580px) / 2);
    }
    .slick-next {
      right: calc((100% - 580px) / 2);
    } */
  }

  .pane-title {
    text-transform: inherit!important;
    font-size: 25px!important; 
  }
  .pane-expert {
    p {
      font-size: 23px;
    }
  }
  .educ {
    p {
      font-size: 30px;
    }
  }
  .pane-boris {

    .img-cont {
      text-align: left!important;

    } 
    .img-text {
      text-align: left!important;
      position: absolute;
      top: 40px;
      left: 200px;
    }
    .img-cont-text {
      margin-top: 25px!important;
    }
    .pane-content{
      margin-top: 65px;
    }
  }
  .region-before-footer-webform {
    .content {
      width: 100%;
      padding:  0 15px;

      #webform-ajax-wrapper-4360 {
        background: url(../images/consultant_pl.jpg) no-repeat;
        background-size: contain;
        width: 612px;
        margin: 0 auto;
        //overflow: hidden;
        form{
          padding-bottom: 250px;
          padding-top: 50px;
          padding-left: 102px;
          fieldset {
            .webform-component--wrap--e-mail {
              margin-bottom: 0;
            }
            &:after {
              background: url(../images/email_min.png) no-repeat;
              top: -20px;
              right: calc(0px + 10px);
              left: unset;
            }
            input {
              width: 100%;
              //width: fill-available;
              margin-right: 16px;
            }
          }
        }
        .webform-component--vopros {
          input {
            margin-right: 70px;
            width: 370px;
            //width: fill-available;
          }
        }
        .webform-component--checkbox {
          float: left;
          margin-left: 85px
        }
        .form-actions {
          float: left;
          margin-left: 84px;
          margin-top: 10px;
        }
      }
    }
  }
  .pane-container-width.special-slider-pane{
    padding-bottom: 36px;
  }
  .view-uwstar-special-front {
    .slick__slide {
      height: 330px!important;
      .slide__content {
        position: relative;
        img {
          width: 260px;
          height: 215px;
          margin: 0 auto;
        }
        .views-field-title {
          .field-content {
            a {
              font-size: 16px;
              height: 69px;
              overflow: hidden;
              display: block;
            }
          }
        }
        .views-field-nothing {
          width: 260px;
          height: 115px;
          top: 215px;
          margin: 0 auto;
          left: 50px;
          .opas-text {
            margin-top: 10px;
            .title {
              font-size: 15px;
              margin-bottom: 6px;
            }
            .body {
              font-size: 10px;
            }
          }
        }
        .views-field-view-node {
          width: 260px;
          margin: 0 auto;
          bottom: -25px;
          right: 0px;
          .field-content {
            margin-top: 7px;
            margin-right: 10px;
            float: right;

          }
        }
      }
    }
    .slick__slide:nth-child(even) {
      .slide__content {
        img {
          // margin-right: 5px;
          //margin-left: 5px;
        }
        .views-field-title{
          &:before {
            top:202px;
          }
          
        }
        .views-field-nothing {
          left: 0;
          margin-right: 5px;
          margin-left: 5px;
        }
        .views-field-view-node {
          margin-right: 5px;
          margin-left: 5px;

        }
      }
    }
    .slick__slide:nth-child(odd) {
      .slide__content {
        img {
             // margin-left: 5px;
          margin-right: 5px;

        }
        .views-field-title{
          &:before {
            top:202px;
          }
          
        }
        .views-field-nothing {
          right: 0;
          margin-right: 5px;

        }
        .views-field-view-node {
          margin-right: 5px;

        }
      }
    }
    .slick-prev {
      left: -30px;
      background-size: cover;
      width: 15px;
      height: 20px;
    }
    .slick-next {
      right: -30px;
      background-size: cover;
      width: 15px;
      height: 20px;
    }
  }
  .pane-educ {
    .educ {
      p {
        font-size: 30px;
      }
      p:nth-child(2) {
        margin-left: calc(100% - 475px);
      }
      p:first-child {
        margin-left: -250px;
      }
    }
  }
  .special-slider-pane {
    padding-bottom: 0;
    .slick-track {
      height: 370px;
    }
    .slick-dots {
      padding-left: 0;
      position: unset;
    }
  }
  .pane-success {
    .success {
      p:nth-child(2) {
        padding-bottom: 0px;
      }
    }
  }
  // .pane-reviews {
  //   .pane-content {
  //     padding: 0 29px;

  //     .view-reviews-front {
  //       // width: 100%;
  //       height: auto;
  //       .slick-dots {
  //         top:unset; 
  //       }

  //     }
  //   }
  // }
  body .region-before-footer-webform .node-webform-full .content #webform-ajax-wrapper-4360 .webform-client-form .form-type-checkbox {
    margin-right: 29px;

  }

  .pane-blog-front-panel-pane-1.pane-container-width,
  .pane-uwstar-special-front-panel-pane-1 .pane-container-width {
    .pane-content {
      width: 540px;
    }
  }
  .pane-uwstar-special-front-panel-pane-1  {
    .pane-content {
      width: 540px;
      .slick__slide {
        img {
          width: 270px;
        }
        img.lazyloader-icon {
          width: 24px;
          height: auto;
        }
      }
    }
  }
}
@include respond-down(md){
  .pane-slider-front-panel-pane-1 {
    .pane-content {
      width: 270px;
    }
  }
  .pane-blog-front-panel-pane-1.pane-container-width {
    .pane-content {
      width: 270px;
    }
  }
  .pane-uwstar-special-front-panel-pane-1.pane-container-width {
    .pane-content {
      width: 270px;
      padding: 0;
    }
  }
  .region-before-footer-webform {
    .content {
      #webform-ajax-wrapper-4360 {
        width: 300px;
      }
    }
  }
  .view-uwstar-special-front {
    .slick-prev {
      left: -15px;
    }
    .slick-next {
      right: -15px;
    }
  }
  // .pane-reviews {
  //   .pane-content {
  //     .view-reviews-front {
  //       width: 320px;
  //     }
  //   }
  // }
  .region-before-footer-webform {
    .content {
      width: 320px;
      padding: 0 10px;
    }
  }
  body .region-before-footer-webform .node-webform-full .panel-body{
    padding: 0;
  }
}
