body {
  font-size: 18px;
  line-height: 24px;
}
h1{
  font-size: 28px;
  line-height: 30px;
  text-align: center;
}
h2{
  font-size: 22px;
  line-height: 24px;
}
.pb-3{
  padding-bottom: 25px;
}
.c-button.bg-1:hover {
  color: #fff !important;
  background-color: #27aee6 !important;
  border-color: #27aee6;
}
.page-user{
  #content{
    @extend .container;
  }
}
  // Page Layout
// -----------------------------------------------------------------------------
#page-wrapper {
  // overflow: hidden;
  padding-top: 120px; 
  width: 100%;
  @include respond-down(lg) {
    padding-top: 0;
  }
  @include respond-down(md) {
    padding-top: 0;
  }
}
iframe {
  max-width: 100%;
}
img {
  max-width: 100%;
}
a {
  transition: all .35s ease-in-out;
}

h1, h2, h3 {
  font-weight: 700;
  text-transform: uppercase;
}
h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h2, .h2 {
  font-size: 28px;
  line-height: 30px;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
}
p {
  margin: 0;
  padding-bottom: 12px;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
hr.clean {
  border-top: 1px solid transparent;
}
select {
  appearance: none;
  background: url(../images/select_arrows.png) right 10px center no-repeat;
  border: none;
  outline: none;
  padding: 0 30px 0 20px;
  &.form-control {
    background: url(../images/select_arrows.png) right 10px center no-repeat;
  }
}
.w262 {
  width: 262px;
}
.w100 {
  width: 100%;
}
.h50 {
  height: 50px;
}
.fl {
  float: left;
}
.bold {
  font-weight: 700!important;
}
.f-14 {
  font-size: 14px;
}
.delay-1 {
  transition: all .8s cubic-bezier(.23,1,.32,1);
}
.delay-2 {
  transition: all .8s cubic-bezier(.23,1,.32,1) .1s;
}
.margin-20-20 {
  margin: 20px 0;
}
.like-h2 {
  color: #333;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
.like-h2--white {
  color: #fff;
}
.like-h4,
.like-h4--small {
  color: #333 !important;
  font-size: 16px !important;
  text-align: left;
}
.like-h4 {
  height: 80px;
  overflow: hidden;
  text-transform: uppercase;
}
.like-h4--small {
  font-weight: 700;
  height: 50px;
  margin: 15px 0;
}
.like-h5,
.like-h5--result {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  line-height: 23px;
}
.like-h5 {
  text-align: center;
}
.like-h5--result {
  color: #0095d8;
  margin-left: 56px;
  padding-bottom: 0;
  padding-top: 20px;
  text-align: left!important;
}
.like-h5--white {
  color: #fff;
  line-height: 23px;
}
.like-h5--blue {
  color: #0095d8;
}
.like-h5,
.like-h5--result {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  line-height: 23px;
}
.vertical-align {
  left: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
}
.tt {
  text-transform: uppercase;
}
.radius-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.price {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.price-s-2,
.price-s-3 {
  color: #fff;
  position: absolute;
}
.price-s-3 {
  font-size: 12px;
  left: -7px;
  padding: 10px 17px;
  top: 10px;
  &.red {
    background: rgba(252,29,29,.8);
  }
}
.color-dark-2-light {
  color: rgba(34,34,34,.6);
}
.color-white {
  color: #fff;
}
.color-white-op {
  color: rgba(255,255,255,.6);
}
.bg-aqua {
  background: #23b0e8;
}
.bg-white {
  background: #fff;
}
.bg-blue-2 {
  background: #23b0e8;
}
.bg-1 {
  background: #f60;
  border: 2px solid #f60;
}
.bg-3 {
  background: #f60;
  border: 2px solid #f60;
}
.button-s-2 {
  background: #23b0e8;
  border-radius: 6px;
  bottom: 0;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  text-decoration: none;
  &:hover {
    background: #206ab0;
    color: #fff;
    text-decoration: none;
  }
}
.icon-block {
  text-align: center;
  .icon-img {
    margin-bottom: 33px;
    @include respond-down(md) {
      margin-bottom: 10px;
    }
  }
  .icon-text {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .icon-title {
    color: #0095d8;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
}
.c-button {
  border-radius: 25px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 14px 18px;
  position: relative;
  transition: all .35s ease-in-out;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &.bg-aqua {
    border: 2px solid #23b0e8;
    color: #fff;
  }
  &.b-40 {
    font-size: 10px;
    height: 40px;
    line-height: 36px;
    padding: 0 18px;
  }
  &.bg-white {
    color: #23b0e8;
    &:hover {
      color: #fff;
    }
  }
  &.bg-1 {
    &:hover {
      background: none;
      color: #f60;
    }
  }
  &.bg-3 {
    color: #fff;
    &:hover {
      background: none;
      color: #23b0e8;
    }
  }
}
.hv-blue {
  &:hover {
    background: #23b0e8;
  }
}
.hv-transparent {
  &:hover {
    background: none;
  }
}
.hv-transparent-c-aqua {
  &:hover {
    background: none;
    color: #23b0e8;
  }
}
.second-title {
  padding-top: 20px;
  //padding-bottom: 65px;
  text-align: center;
  line-height: 30px;
  .h2-style, 
  .h3-style{
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
  } 
  h1, h2, h3, .h2-style, .h3-style {
    a {
      color: #333;
      text-decoration: none;
    }
  }
  p {
    margin: 0 140px;
    @include respond-down(lg) {
      margin: 0 60px;
      font-size: 25px;
    }
    @include respond-down(md) {
      margin:0;
    }
  }
}
.pane-1 {
  .second-title {
    padding: 0;
    h1 {
      margin: 0;
    }
    p {
      line-height: 22px;
      padding-top: 24px;
    }
  }
}
.pane-title {
  margin: 0;
  padding-top: 65px;
  padding-bottom: 65px;
  text-align: center;
}
h1.pane-title {
  font-size: 30px;
  font-weight: 400;
}
h2.pane-title {
  font-size: 24px;
  text-transform: uppercase;
}

.swiper-container-horizontal,
.swiper-container {
  overflow: hidden;
}
.swiper-wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
}
.swipe-slide {
  list-style: none;
  margin: 0;
  padding: 0;
}
.swiper-arrow-left,
.swiper-arrow-right {
  cursor: pointer;
  height: 40px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 320ms cubic-bezier(.565,.42,.355,1.155);
  width: 40px;
  z-index: 5;
}
.offers-arrow {
  border: 0;
  background: rgba(35,176,232,.7);
  span {
    color: #fff;
    font-size: 16px;
    padding-top: 13px;
  }
  &:hover {
    border: 0;
    background: rgba(35,176,232,.7);
  }
  &.swiper-arrow-left {
    left: 0;
    border-radius: 0 5px 5px 0;
  }
  &.swiper-arrow-right {
    right: 0;
    border-radius: 5px 0 0 5px;
  }
  &.color-4 {
    &:hover {
      span {
        color: #222;
      }
    }
  }
}
.acc-body,
.detail-content {
  color: #000;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  p {
    font-size: 14px;
    font-weight: 400;
  }
  &.color-1 {
    p {
      color: #2d3e50;
    }
  }
}
.acc-body {
  .arrows {
    margin-bottom: 25px;
  }
}
.top-baner {
  position: relative;
  width: 100%;
}

.b-consult-form {
  background-size: cover;
  .content {
    @extend .container;
    position: relative;
  }
  .second-title {
    color: #0e0e0e;
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    margin-top: 53px;
    @include respond-down (lg) {
      margin-top: 25px;
    }
    @include respond-down (md) {
      font-size: 22px;
      margin-top: 10px;
      padding:0 40px;
      p{
        font-size: 22px;
        line-height: 26px;
        padding-bottom: 45px;
      }
    }
  }
  form {
    padding-top: 6px;
    padding-bottom: 103px;
    padding-left: 250px;
    margin-bottom: 127px;
    @include respond-down (md) {
      padding-bottom: 30px;
    }
    // .webform-component--checkbox >label {
    //   display: none;
    // }
  }
  .form-item {
    padding: 0 15px;
    &:first-child {
      padding-left: 0;
    }
    @media (max-width: 991px) {
      padding-left:initial;
      padding-right:initial;
    }
  }
  input[type=text] {
    background: #1e6e93;
    border-radius: 0;
    border: 1px solid #fff;
    padding-left: 10px;
    color: #fff; 
    height: 50px;
    .error {
      border-color: red;
    }
  }
  select {
    background-color: #fff;
    border: 1px solid rgba(255,255,255,.5);
    border-radius: 25px;
    color: #777;
    font-size: 14px;
    height: 50px;
    opacity: 1;
    padding: 0 20px 0 44px;
  }
  .form-actions {
    @extend .col-md-3;
    margin: 0;
    padding-left: 15px;
    @media (max-width: 991px) {
      padding-left:initial;
    }
  }
  input[type=submit] {
    @extend .c-button;
    @extend .bg-3;
    @extend .w100;
    @extend .h50;
    &:hover {
      background: #fff;
      color: #f60;
    }
  }
}
.pane-startravel-startravel-search-forms {
  background: #e1edf3;
}
.baner-tabs {
  .drop-tabs {
    text-align: center;
    .nav-tabs {
      border-bottom: 5px solid #fff;
      height: 59px;
      list-style: none;
      margin: 0;
      position: relative;
      text-align: center;
      width: 100%;
      @media (max-width:639px) {
        padding:0;
      }
      .click-tabs {
        display: inline-block;
        float: none;
        list-style: none;
        margin: 0 2px;
        span {
          border: 1px solid #fff;
          border-bottom: 0 none;
          border-radius: 0;
          color: #000;
          cursor: pointer;
          display: block;
          float: left;
          font-size: 12px;
          font-weight: 700;
          margin: 0;
          padding: 18px 25px;
          position: relative;
          text-transform: uppercase;
          &:hover {
            color: #F60;
          }
        }
        &.active {
          span {
            background: #fff;
            color: #F60;
          }
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      display: none;
      padding: 65px 0 50px 0;
      &.active {
        display: block;
      }
      .form-actions,
      .form-item {
        @extend .col-md-3;
        @extend .col-xs-12;
        margin: 0 0 15px 0;
        padding: 0 15px;
        @include respond-down(lg) {
          padding: 0 16px 0 20px;
        }
        @include respond-down(md) {
          padding: 0;
        }
      }
      @media (min-width: 1170px) {
        .d-flex .form-item.form-type-select {
          padding: 0;
          width: 24%;
          //border: 1px solid red;
        }
      }
      @include respond-down(lg) {
        .d-flex .form-item.form-type-select {
          padding: 0;
          width: 47%;
          //border: 1px solid red;
          &:nth-child(2){
            page-break-after: always;
            //break-after: always;
          }
        }
      }
      @include respond-down(md) {
        .d-flex .form-item.form-type-select {
          padding: 0;
          width: 100%;
          //border: 1px solid red;
          page-break-after: always;
          //&:nth-child(2){
            //break-after: always;
          //}
        }
      }
      select {
        appearance: none;
        background-color: #fff;
        border-radius: 25px;
        border: none;
        color: #777;
        font-size: 14px;
        height: 50px;
        outline: none;
        padding: 0 20px;
        transition: all .3s ease-in;
        width: 100%;
      }
      input[type=submit] {
        @extend .c-button;
        @extend .w100;
        @extend .h50;
        background-color: #23b0e8;
        border: 2px solid #23b0e8;
        outline: none;
        &:hover {
          background: none;
          color: #23b0e8;
        }
      }
    }
  }
}

.simple-tab {
  .nav-tab {
    font-size: 0;
    position: relative;
    top: 1px;
    z-index: 2;
  }
  .nav-tab-item {
    border: 1px solid #eaeaea;
    border-left: none;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    text-transform: uppercase;
    @include respond-down (lg) {
      border-right: none;
    }
    &.active {
      border-bottom: none;
       
    }
    &:first-child {
      border-left: 1px solid #eaeaea;
      @include respond-down (lg) {
        border-left: none;
      }
    }
    &:last-child {
      border-bottom: 1px solid #eaeaea;
    }
  }
  .tab-info {
    border: 1px solid #eaeaea;
    display: none;
    padding: 26px 32px;
  }
  &.color-1 {
    .tab-info {
      &.active {
        background: #fff;
        display: block;
        @include respond-down (lg) {
        border-bottom: 1px solid #eaeaea;
        }
      }
    }
    .nav-tab-item {
      background: #f8f8f8;
      color: #222;
      @include respond-down (lg) {
        background: unset;
        color: #3c6fa4;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        line-height: 29px;
        text-transform: unset;
        padding: 10px 0px;
        padding-left: 10px;
      }

      &.active {
        background: #fff;
      }
    }
  }
}

.item-list {
  ul.pagination {
    > li {
      display: inline-block;
      height: 40px;
      margin: 7px;
      padding: 0;
      vertical-align: middle;
      width: 40px;
      span,
      a {
        border-radius: 4px;
        border: none;
        color: #bebebe;
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        height: 40px;
        line-height: 40px;
        padding: 0;
        text-align: center;
        width: 40px;
        &:hover {
          background: #23b0e8;
          color: #fff;
        }
      }
      span {
        background: #23b0e8;
        color: #fff;
      }
      &.pager-first {
        display: none;
      } 
      &.pager-ellipsis {
        display: none;
      }
      &.pager-last {
        display: none;
      }
    }
  }
}

.inner-banner {
  background: url(../images/bg.jpg) center no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
  &.style-6 {
    height: 211px;
  }
}
.region-content {
  background: #f8f8f8;
  padding-bottom: 20px;
  // padding-top: 20px;
}
.breadcrumb {
  padding: 15px 15px;
  background: none;
  padding-left: 0;
  height: 60px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 5px;
  @extend .container;
  a {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    color: #090909;
    text-decoration: underline;
    @include respond-down (md) {
      line-height: 20px!important;
    }
    &:hover {
      color: #23b0e8;
    }
  }
  .delimetr {
    margin: 0 5px;
  }
  @include respond-down (md) { 
    margin-bottom: 50px;
    line-height: 16px;
  }
}

.uwstar-sidebar-layout {
  .middle-row {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  .sidebar-col {
    .pane-title {
      color: #222;
      font-size: 22px;
      font-weight: 700;
      margin: 0 0 27px 0;
      padding: 0;
      text-align: left;
      text-transform: uppercase;
    }
  }
}

.detail-wrapper {
  padding-top:0;
}
.detail-content {
  .slider-wth-thumbs {
    margin-bottom: 25px;
  }
}
.detail-content-block {
  margin-bottom: 65px;
}

#webform-ajax-wrapper-4360 {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 5px 24.3px 2.7px rgba(83, 98, 130, 0.15);
  background: url(../images/consultant.jpg) no-repeat;
  margin-left: 62px;
  width: calc(100% - 120px);
  @include respond-down (lg) {
    margin-left: 0;
  }
  @include respond-down (md) {
    margin-left: 0;
  }
  .captcha {
    margin-left: 83px;
    @include respond-down (md) {
      margin: 0;
    }
  }
}
.webform-component--telefon {
  padding-left: 0!important; 
}

.b-consult-form .form-actions {
  @media screen and (min-width: 992px) {
    width: 47%;
  }
  
}
.pane-education {
  background-color: #f8f8f8;
  .pane-title {
    color: #0f0f0f;
    font-family: "Open Sans", sans-serif;
    font-size: 29px;
    font-weight: 600;
    line-height: 36px;
    text-transform: unset;
    padding-top: 46px;
    @include respond-down(md) {
      padding-top: 30px;
      font-size: 22px !important;
    }
  }
  .pane-content {
    padding-bottom: 59px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top:0;
    @include respond-down(lg) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include respond-down(md) {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 20px;
      .icon-block {
        margin-bottom: 24px;
      }
    }
    p {
      color: #0d0d0d;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      padding-bottom: 22px;
      @include respond-down(md) {
        font-size: 16px;
        line-height: 1.15;
      }
    }
  }
}
.pane-preim {
  background-color: #364e67;
  padding-bottom: 21px;
  .pane-title {
    color: #fefefe;
    font-family: "Open Sans", sans-serif;
    font-size: 29px;
    font-weight: 600;
    line-height: 36px;
    padding-top: 43px;
    text-transform: unset;
    padding-bottom: 55px;
    @include respond-down(lg) {
      padding-bottom: 43px;
    }
    @include respond-down(md) {
      padding-top: 45px;
      font-size: 21px !important;
      line-height: 1.3;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .text-preim {
    text-align: center;
    color: #00a9e8;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    padding: 45px 0;
    @include respond-down(lg) {
      padding: 10px 85px 41px 85px;
    }
    @include respond-down(md) {
      padding: 10px 0;
      left: 0;
      margin: 0;
      font-size: 16px;
    }
  }
  .icon-block {
    padding: 0 20px;
    min-height: 177px;
    @include respond-down(lg) {
      padding-bottom: 40px;
      .icon-img{
        margin-bottom: 28px;
      }
    }
    @include respond-down(md) {
      padding-bottom: 0;
    }
    p {
      color: #fffefe;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      @include respond-down(md) {
        padding-bottom: 25px;
      }
    }
  }

}
.pane-expert {
  h2 {
    color: #3f566e;
    font-family: "Open Sans", sans-serif;
    font-size: 29px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    padding-bottom: 88px;
    padding-top: 69px;
    text-transform: none;
    @include respond-down(lg) {
      font-size: 23px;
      line-height: 28px;
      padding: 45px;
    }
    @include respond-down(md) {
      padding: 25px 10px;
      min-height: 176px;
    }
  }
}
.pane-our {
  background: url(../images/bg-preim.jpg) center no-repeat;
  background-size: cover;
  .row {
    margin-top: 92px;
    margin-bottom: 42px;
    @include respond-down(lg) {
      margin-top: 66px;
      margin-bottom: 19px;
    }
    @include respond-down(md) {
      margin-top: 60px;
      margin-bottom: 30px;
      padding: 0 15px;
    }
  }
  .pane-content {
    .icon-block{
      margin-bottom: 82px;
      color: #fffefe;
      font-family: "Open Sans", sans-serif;
      font-size: 25px;
      font-weight: 600;
      line-height: 21px;
      text-align: start;
      padding: 0 65px 0 52px;
      margin-top: 8px;
      @include respond-down(lg) {
        padding: 20 px 50px 0 110px;
        margin-bottom: 48px;
      }
      @include respond-down(md) {
        padding: 5px 0 0 0!important;

      }
      p {
        color: #fffefe;
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 27px;
      }
      .icon-img {
        margin-top: -10px;
        position: absolute;
        top: 0;
        left: 0;
        @include respond-down(lg) {
          left: 52px;
        }
        @include respond-down(md) {
          left: 0;
        }
      }
      .icon-text {
        color: #fffefe;
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        @include respond-down(lg) {
          margin-top: 6px;
        }
        @include respond-down(md) {
          font-size: 14px;
          margin-top: 0;
          line-height: 1.4;
          margin-bottom: 5px;
        }
      }
    }
  }
}
.pane-boris {
  background-color: #f8f8f8;
  .pane-content {
    margin-top: 78px;
    margin-bottom: 40px;
    @include respond-down(md) {
      padding: 0 15px;
      margin-bottom: 50px;
    }
    .col-md-4 {
      @include respond-down(lg) {
        width: 100%;
        display: flex;
      }
      @include respond-down(md) {
        display: inline;
      }

    }
    .col-md-8 {
      @include respond-down(lg) {
        width: 100%;
      }
    }
    .img-cont {
      text-align: center;
      margin-left: 110px;
      @include respond-down(lg) {
        margin-left: 17px;
      }
      @include respond-down(md) {
        margin-left:0;
      }
    }
    .img-text {
      color: black;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-align: right;
      margin-right: 64px;
      margin-top: 33px;
      @include respond-down(lg) {
        position: relative;
        left: 46px;
        margin-top: 5px;
      }
      @include respond-down(md) {
        position: unset;
        margin-right: 0;
        margin-top: 33px;
      }
      p {
        padding-bottom:0;
      }
      b {
        font-size: 13px;
      }
    }
    .cont-text {
      color: black;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      margin-left: 24px;
      @include respond-down(md) {
        margin-left:0;
        font-size: 14px;
        line-height: 1.3;
      }
      p {
        padding-bottom: 23px;
        @include respond-down(md) {
          padding-bottom: 17px;
        }
      }
    }
    .img-cont-text {
      margin-top: -8px;
      margin-bottom: 27px;
      margin-left: 24px;
      @include respond-down(lg) {
        margin-bottom: 17px;
      }
      @include respond-down(md) {
        margin-top: 6px !important;
        margin-left: 0;
      }
    }
    .img-sign {
      text-align: right;
      margin-right: 89px;
      @include respond-down(lg) {
        margin-right: 45px;

      }
      @include respond-down(md) {
        margin-right:0;
        img{
          width: 125px;
          margin-left: auto;
        }
      }
    }
  }
}
.node-webform-full {
  .form-type-checkbox {
    float: right;
    margin-right: 95px;
    color: #444;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    input {
      width: 20px;
      height: 20px;
      border: 1px solid #4bade1;
      margin: 0;
    }
  }
  .webform-component--vopros {
    margin-left: 70px;
    @include respond-down(lg) {
      margin-left: 84px;
    }
    @include respond-down(md) {
      margin-left: 84px;
    }
    textarea {
      width: 598px;
      height: 110px;
      border: none;
      background-color: #e4f4ff;
      border-radius: 5px;
      box-shadow: none;  
      color: #444;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 36px;
      @include respond-down(lg) {
        width: 400px;
      }
      @include respond-down(md) {
        width: 300px;
        line-height: 18px;
      }
    }
  }
  .panel-default {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0 0 0 70px;
    padding: 0;
    background: none;
    @include respond-down(lg) {
      margin-right: 40px;
    }
    @include respond-down(md) {
      margin-right: 40px;
    }
    &:after {
      background: url(../images/email.png)  no-repeat;
      content: '';
      width: 72px;
      height: 76px;
      display: block;
      position: absolute;
      top: 80px;
      right: 130px;
    }    
    .webform-component {
      padding: 0;
    }
    input, select,.jq-selectbox,.jq-selectbox__select {
      height: 36px;
      box-shadow: none;
      width: 400px;
      border: none;
      background: none;
      color: #444;
      border-radius: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 36px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: #4bade1;
      border-top-style: none;
      margin-bottom: 16px;
    }
    .jq-selectbox__select {
      border-radius: 0!important;
      .jq-selectbox__trigger {
        background-image: url(../images/arrow_filt.png) !important ;
        width: 19px!important;
      }
    }
    .jq-selectbox__select-text{
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 36px;
      @include respond-down(md) {
        line-height: 15px;
      }
    }
    .jq-selectbox,.jq-selectbox__select{
      margin-bottom: 0;
      padding-left: 6px;
      max-width: 100%;
      @include respond-down(lg) {
        padding-left: 3px;
        height: 45px;
      }
    }
    .field-wrap {
      border: none!important;
      .panel-body {
        border: none;
        padding: 0;
      }
    }
  }
  .form-actions {
    float: right;
    margin-right: 0;
    #edit-webform-ajax-submit-4360 {
      background-color: #00a9e8;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font-family: "Open Sans", sans-serif;
      font-size: 19px;
      font-weight: 600;
      //line-height: 36px;
      text-transform: uppercase;
      width: 250px;
      height: 58px;
      line-height: 1;
      &:hover{
        background-color: #4c965a;
        color: #ffffff;
      }
      @include respond-down(md) {
        width: 285px;
      }
    }
  } 
}
.pane-success {
  background-color: #00a9e8;
  .success {
    text-align: center;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 49px;
    font-weight: 600;
    line-height: 49px;
    margin-top: 59px;
    margin-bottom: 42px;
    @include respond-down(lg) {
      font-size: 30px;
      margin-top: 30px;
      margin-bottom: 40px;
    }
    @include respond-down(md) {
      font-size: 23px;
      margin-top: 50px;
      margin-bottom: 40px;
    }
    p:first-child {
      margin-left: -208px;
      padding-bottom:0;
      @include respond-down(lg) {
        margin-left: 39px;
        text-align: left;
        line-height: 1;
        padding-top: 13px;
        height: auto;
      }
      @include respond-down(lg) {
        margin-left: 0;
        padding-top: 0;
      }
    }
    p:nth-child(2) {
      margin-left: 332px;
      padding-bottom: 22px;
      @include respond-down(lg) {
        margin-left: 10px;
        height: auto;
        text-align: right;
        padding-right: 39px;
        margin-top: -9px;
      }
    }
    .zigazaga{
      @include respond-down(lg) {
        img{
          width: 84px;
        }
      }
      @include respond-down(md) {
        img{
          width: 65px;
        }
      }
    }
  }
}
.pane-blog-front-panel-pane-1 {
  background: url(../images/Edinburgh.jpg) center no-repeat;
  background-size: cover;
  @include respond-down(lg) {
    padding-bottom: 0;
  }
  @include respond-down(md) {
    padding-bottom: 0;
  }
  .pane-title {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 29px;
    font-weight: 600;
    line-height: 36px;
    text-transform: inherit;
    padding-top: 63px;
    padding-bottom: 57px;
    @include respond-down(lg) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @include respond-down(md) {
      padding: 47px 15px 36px 15px;
      font-size: 20px !important;
      line-height: 1.3;
    }
  }
  .views-field-field-image {
    img {
      border-radius: 5px;
    }
  }
  .views-field-nothing {
    background-color: rgba(255,255,255, 0.8);
    width: 338px;
    position: absolute;
    top: 255px;
    padding: 0 20px;
    height: 193px;
    span {
      .opas-text {
        margin-top: 20px;
        display: block;
        .title {
          color: #0f0f0f;
          font-family: "Open Sans", sans-serif;
          font-size: 20px;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 10px;
          display: block;
          height: 42px;
          overflow-y: hidden
        }
      }
    }
  }
  .views-field-body {
    position: absolute;
    top: 309px;
    padding: 0 31px;
  }
  .views-field-view-node {
    width: 338px;
    background: #FFF;
    height: 35px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    .field-content {
      /* autoprefixer: off */
      vertical-align: -webkit-baseline-middle;
      margin-left: 10px;
    }
    span {
      a {
        color: #ff6401!important;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        text-decoration: none;
      }
    }
  }
  .views-slideshow-controls-text-previous-processed {
    position: absolute;
    top: 218px;
    left: -25px;
    background: url(../images/arr_prev.png) center no-repeat;
    width: 17px;
    height: 30px;
    a {
      font-size: 0;
    }
  }
  .views-slideshow-controls-text-next-processed {
    position: absolute;
    top: 218px;
    right: -25px;
    background: url(../images/arr_next.png) center no-repeat;
    width: 17px;
    height: 30px;
    a {
      font-size: 0;
    }
  }
  .views-slideshow-controls-text-pause-processed {
    display: none;
  }
}
.pane-reviews-front-panel-pane-1, .pane-reviews-front-panel-pane-2, .pane-reviews-front-panel-pane-3 {
  background-color: #00a9e8;
  .pane-title {
    text-transform: unset;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 29px;
    font-weight: 600;
    line-height: 36px;
    padding-top: 57px;
    padding-bottom: 35px;
    @include respond-down(lg) {
      padding-top: 34px;
      padding-bottom: 22px;
    }
  }
}
.front-slider {
  .slick-list {
    @include respond-down(md) {
      height: 310px!important;
    }
  }
  .views-field-title {
    text-align: center;
    padding-right: 25px;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.15;
    color: #0f0f0f;
    padding-top: 10px;
    @include respond-down(md) {
      padding-right:0;
      padding-top: 10px;
    }
  }
  .slick-dots {
    padding: 0;
    display: flex!important;
    justify-content: center;
    list-style: none;
    margin-top: 50px;
    @include respond-down(lg) {
      margin-top: 30px;
    }
    @include respond-down(md) {
      margin-top: 40px;
      padding-bottom: 10px;
    }
    .slick-active {
      button {
      background: url(../images/dot_act.png) center no-repeat;
      }
    }
    button {
      background: url(../images/dot.png) center no-repeat;
      font-size: 0; 
      border: none;
      outline: none; 
      width: 12px;
      height: 12px;
      padding:0 12px;
    }
  }
  .slick-next,.slick-prev  {
    position: absolute;
    right: -35px;
    background: url(../images/arr_next.png) center no-repeat;
    width: 21px;
    height: 32px;
    font-size:0;
    border: none;
    top: 115px;
    outline: none;
    cursor: pointer;
    @include respond-down(lg) {
      right: 0;
      background-size: cover;
      width: 15px;
      height: 20px;
    }
    @include respond-down(md) {
      right: -15px;
    }
  }
  .slick-prev {
    left: -35px;
    right: inherit;
    background: url(../images/arr_prev.png) center no-repeat;
    @include respond-down(lg) {
      left: 0;
      background-size: cover;
    }
    @include respond-down(md) {
      left: -15px;
    }
  }
}
.view-blog-front {
  .slick-track {
    @include respond-down(lg) {
      height: 380px;
    }
  }
  .slick-dots {
    display: flex!important;
    justify-content: center;
    list-style: none;
    padding: 0;
    padding-top: 15px;
    @include respond-down(lg) {
      padding-top: 0;
      padding-bottom: 2px;
    }
    .slick-active {
      button {
      background: url(../images/dot_act.png) center no-repeat;
      }
    }
    button {
      background: url(../images/dot.png) center no-repeat;
      font-size: 0; 
      border: none;
      outline: none; 
      width: 12px;
      height: 12px;
      padding: 40px 12px;
    }
  }
  .slick-next  {
    position: absolute;
    right: -35px;
    background: url(../images/arr_next.png) center no-repeat;
    width: 21px;
    height: 32px;
    font-size:0;
    border: none;
    top: 215px;
    outline: none;
    cursor: pointer;
    @include respond-down (lg) {
      top: 165px;
    }
    @include respond-down(md) {
      top: 165px;
      right: -20px;
      background-size: cover;
      width: 14px;
      height: 20px;
    }
  }
  .slick-prev {
    position: absolute;
    left: -35px;
    background: url(../images/arr_prev.png) center no-repeat;
    width: 21px;
    height: 32px;
    font-size:0;
    border: none;
    top: 215px;
    outline: none;
    cursor: pointer;
    @include respond-down (lg) {
      top: 165px;
    }
    @include respond-down(md) {
      top: 165px;
      left: -20px;
      background-size: cover;
      width: 14px;
      height: 20px;
    }
  }
}
.view-reviews-front {
  width: 870px;
  min-height: 385px;
  margin: 0 auto;
  background-color: #feffff;
  border-radius: 5px;
  box-shadow: 0 5px 24.3px 2.7px rgba(83, 98, 130, 0.3);
  @include respond-down (lg) {
    min-height: auto;
    width: 100%;
  }
  @include respond-down (md) {
	 width: 100%;
  }
  .slide__content {
    padding-left: 100px;
    @include respond-down (lg) {
      padding-left: 23px;
    }
    .views-field-field-review-name-age {
      float: left;
      color: #111;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      margin-top: 20px;
      @include respond-down (lg) {
        margin-top: 30px;
        display: none;
      }
      @include respond-down(md) {
        text-align: center;
        float: unset;
        margin-top: 120px;
      }
    }
    .views-field-field-educational {
      float: right;
      width: auto;
      display: flex;
      padding-top: 39px;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      padding-right: 50px;
      @include respond-down(lg) {
        position: absolute;
        padding-top: 28px;
        display: inline;
        float: none;
        //margin: 0;
        //height: 18px;
        line-height: 1;
        margin-right: 90px;
        height: 80px;
        &:after{
          content: "";
          width: 42px;
          height: 2px;
          display: inline-block;
          background-color: #63beef;
        }
      }
      @include respond-down(md) {
        top: 0;
        width: 300px;
        left: calc((100% - 300px)/2); 
        display: block;
        position: relative;
        padding: 35px 15px 0 15px;
        margin: 0;
        text-align: center;
        height: auto;
        >span{
          padding-bottom: 30px;
        }
        &:after{
          content: none;
        }
        >span{
          display: inline-block;
          width: 100%;
        }
      }
      .views-label-field-educational {
        color: #63beef;
        margin-right: 5px;
      }
      .field-content {
        color: #0e0e0e;
        p {
          @include respond-down(md) {
            padding-bottom: 0px;
          }
        }
      }
    }
    .views-field-body {
      padding-top: 90px;
      color: #101010;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      padding-right: 100px;
      @include respond-down(lg) {
        padding-top: 100px;
        padding-right: 10px;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
      }
      @include respond-down (md) {
        padding-top: 25px;
        padding-left: 10px;
      }
    }
  }

  .slick-dots {
    display: flex!important;
    justify-content: center;
    list-style: none;
    position: absolute;
    width: 100%;
    bottom: -121px;
    padding: 0;
    @include respond-down(lg) {
      bottom: -91px;
    }
    .slick-active {
      button {
      background: url(../images/dot_act_wh.png) center no-repeat;
      }
    }
    button {
      background: url(../images/dot_wh.png) center no-repeat;
      font-size: 0; 
      border: none;
      outline: none; 
      width: 12px;
      height: 12px;
      padding: 40px 12px;
    }
  }
  .slick-next  {
    position: absolute;
    right: -20%;
    background: url(../images/arr_next_wh.png) center no-repeat;
    width: 21px;
    height: 32px;
    font-size:0;
    border: none;
    top: 41%;
    outline: none;
    cursor: pointer;
    @include respond-down(lg) {
      right: -25px;
      top: calc(40% + 30px);
    }
    @include respond-down(md) {
      right: -25px;
    }
  }
  .slick-prev {
    position: absolute;
    left: -20%;
    background: url(../images/arr_prev_wh.png) center no-repeat;
    width: 21px;
    height: 32px;
    font-size:0;
    border: none;
    top: 41%;
    outline: none;
    cursor: pointer;
    @include respond-down(lg) {
      left: -25px;
      top: calc(40% + 30px);
    }
    @include respond-down(md) {
      left: -25px;
    }
  }
}

.special-slider-pane {
  padding-bottom: 108px;
  .slick-list {
    @include respond-down(md) {
      height: 365px!important;
    }
  }
  .slick-track {
    height: 450px;
    .slick__slide {
      position: relative;
      width: calc(1132px / 3);
      margin: 25px;
      // border-radius: 
      // margin-left: 22px;
      // margin-top: 22px;
      height: 425px;
      border-radius: 5px;
      box-shadow: 0 5px 22px 2.7px rgba(83, 98, 130, 0.3);
      @include respond-down(lg) {
        width: 271px;
        margin: 5px;
        box-shadow: 0 5px 4px 2.7px rgba(83, 98, 130, 0.3);
      }
      @include respond-down(md) {
        width: 320px;
        height: 330px!important;
        margin: 10px;

      }
      img {
        border-radius: 5px 5px00;
      }
      .views-field-view-node {
        text-align: right;
        color: #ff6401!important;
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 23px;
        position: absolute;
        bottom: 0;
        right: 10px;
        a {
          color: #ff6401!important;
        }
      }
      .views-field-title {
        margin-top: 15px;
        padding: 0 10px;
         &:before {
          content: '';
          background: url(../images/orange_line.png);
          width: 106px;
          height: 13px;
          position: absolute;
          top: 262px;
          left: 0;
         }
         a {
          color: #0f0f0f;
          font-family: "Open Sans", sans-serif;
          font-size: 19px;
          font-weight: 600;
          line-height: 23px;
         }
      }
    }
  }
  .slick-dots {
    display: flex!important;
    justify-content: center;
    list-style: none;
    position: absolute;
    left: 0;
    bottom: -76px;
    padding-left: 0;
    width: 100%;

    .slick-active {
      button {
        background: url(../images/dot_act.png) center no-repeat;
      }
    }
    button {
      background: url(../images/dot.png) center no-repeat;
      font-size: 0; 
      border: none;
      outline: none; 
      width: 12px;
      height: 12px;
      padding: 40px 12px;
      @include respond-down(lg) {
        padding: 0 12px;
      }
    }
  }
  .slick-next  {
    position: absolute;
  //  top: 218px;
    right: -46px;
    background: url(../images/arr_next.png) center no-repeat;
    width: 21px;
    height: 32px;
    font-size:0;
    border: none;
    top: 170px;
    outline: none;
    cursor: pointer;

  }
  .slick-prev {
    position: absolute;
 //   top: 218px;
    left: -38px;
    background: url(../images/arr_prev.png) center no-repeat;
    width: 21px;
    height: 32px;
    font-size:0;
    border: none;
    top: 170px;
    outline: none;
    cursor: pointer;
  }
}
.pane-educ   {
  .h2 {
    text-align: center;
    color: #00a9e8;
    font-family: "Open Sans", sans-serif;
    font-size: 49px;
    font-weight: 600;
    line-height: 49px;
    margin-top: 60px;
    margin-bottom: 30px;
    text-transform: none;
    @include respond-down(lg) {
      margin-top: 47px;
      margin-bottom: 29px;
      font-size: 30px;
      line-height: 33px
    }
    @include respond-down (md) {
      margin-top: 55px;
      margin-bottom: 24px;
      font-size: 30px;
      line-height: 33px
    }
    p:first-child {
      margin-left: -397px;
      padding-bottom:0;
      @include respond-down(lg) {
        margin-left: 0;
        text-align: left;
      }
      @include respond-down(md) {
        text-align: center;
      }
    }
    p:nth-child(2) {
      margin-left: 252px;
      text-transform: lowercase;
      padding-bottom: 22px;
      @include respond-down(lg) {
        margin-left: 0;
        text-align: right;
      }
      @include respond-down(md) {
        text-align: center;
      }
    }
    @include respond-down (md) {
      .zigazaga img{
        width: 75px;
      }
    }
  }
}
.slick {
  position: relative;
}
.webform-component--wrap {
  position: relative;
}
.pane-accordion {
  padding-bottom: 76px;
  @include respond-down (md) {
    padding-bottom: 50px;
  }
  .pane-title {
    position: relative;
    text-transform: unset;
    color: #0e0e0e;
    font-family: "Open Sans", sans-serif;
    font-size: 29px;
    font-weight: 600;
    line-height: 36px;
    padding-top: 58px;
    padding-bottom: 58px;
    @include respond-down (lg) {
      padding-top: 50px;
    }
    @include respond-down (md) {
      font-size: 20px !important;
      line-height: 1.4;
      padding-bottom: 44px;
    }
    &:after {
      background: url(../images/pram.png) center no-repeat;
      content: '';
      width: 50px;
      height: 7px;
      position: absolute;
      left: calc((100% - 50px)/2);
      top: 120px;
      @include respond-down (lg) {
        top: 106px;
      }
      @include respond-down(md) {
        bottom: 18px;
        top: auto;
      }
    }
  }
  .views-row {
    max-width: 850px;
    margin: 0 auto;
    .views-accordion-header {
      border: 1px solid #949494;
      border-radius: 0;
      background: none;
      margin-top: 10px;
      padding: 3px 20px;
      @include respond-down (lg) {
        padding: 3px 15px 3px 27px;
      }
      @include respond-down (md) {
        padding: 3px 15px 3px 21px;
        margin-bottom: 20px;
      }
      .ui-accordion-header-icon {
        position: unset;
        float: right;
        margin-top: 8px;
      }
      .field-content {
        display: flex;
        padding-top: 12px;
        margin-right: 30px;
        @include respond-down(md) {
          display: block;
        }
        span {
          color: #00a9e8;
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 19px;
        }
        p {
          color: #0e0e0e;
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 19px;
          width: calc(100% - 75px); 
          @include respond-down(lg) {
            font-size: 16px;
          }
          @include respond-down(md) {
            font-size: 16px;
            padding-bottom: 5px;
          }
        }
        .mif {
          width: 70px;
          margin-right: 0;
          @include respond-down(lg) {
            width: 75px;
          }
          @include respond-down(md) {
            width: 100%;
            padding-bottom: 15px;
            font-size: 16px;
          }
        }
      }
    }
    .ui-state-active {
      .ui-icon {
        background: url(../images/minus_icon.png) center no-repeat!important;
        width: 24px;
        height: 2px;
      }
    }
    .ui-state-default  {
      .ui-icon {
      background: url(../images/plus_icon.png) center no-repeat;
      width: 24px;
      height: 24px;
      }
    }
    .ui-accordion-content {
      padding: 1em 25px!important;
      height: auto!important;
      .views-field {
        box-sizing: unset;
        .field-content {
          box-sizing: unset;
          color: #0e0e0e;
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          border-left-color: #00a9e8; 
          border-left-style: solid; 
          border-left-width: 3px;
          padding: 0 25px;
        }
      }
    }
  }
}

.all-forms {
  position: relative;
  .filter-schools {
    background: #27aee6;
    .block__title {
      text-align: center;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 21px;
      font-weight: 600;
      line-height: 35px;
      text-transform: uppercase;
      padding-top: 36px;
      margin-top: 10px;
      margin-bottom: 10px;
      @include respond-down(lg) {
        margin-top: 0;
      }
      @include respond-down(md) {
        padding-top: 35px;
        padding-bottom: 17px;
        width: 320px;
        margin: 0 auto;
        line-height: 1.4;
      }
    }
    .tab-pane {
      display: block!important;
      padding: 5px 0 22px 0!important;
      @include respond-down(md) {
        padding: 5px 0 32px 0!important;
      }
    }
    form {
      padding: 0px 10px;
      border-radius: 0;
      @include respond-down(lg) {
        padding: 0px 20px;
      }
      @include respond-down(md) {
        padding: 0px;
      }
      .jq-selectbox__select {
        border-radius: 0!important;
        .jq-selectbox__trigger {
          background-image: url(../images/arrow_filt.png) !important ;
          width: 19px!important;
        }
      }
      .form-wrapper {
        position: absolute;
        left: calc(50% - 140px);
        @include respond-down(md) {
          left: calc(50% - 160px);
        }
        #edit-submit--2 {
          border: 4px solid #FFF;
          border-radius: 0;
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 35px;
          padding-top: 5px!important;
          cursor: pointer;
          @include respond-down(lg) {
            width: 253px;
            margin-top: 13px;
          }
          @include respond-down(md) {
            width: 243px;
            margin: 0 auto;
            margin-top: 20px;
            display: block;
          }
          &:hover {
            background-color: #ff6701;
            border: 4px solid #ff6701;
            color: white;
          } 
        }
      }
      .form-item {
        @include respond-down(lg) {
          //width: 50%!important;
        }
        @include respond-down(md) {
          //width: 100%!important;
        }
      }
    }
  }
  .search-schools {
    position: absolute;
    right: calc((100% - 1102px)/2);
    top: 12%;
    @include respond-down(lg) {
      display: none;
    }
    @include respond-down(md) {
      display: none;
    }
    .form-item-Searchworld {
      width: 160px;
      input[type="text"] {
        background-color: unset;
        border-top: none;
        border-radius: unset;
        border-left: none;
        border-right: none;
        color: #fff;
        box-shadow: none;
        border-bottom: 1px solid #c3e7ff;
        padding-left: 0;
      }
      input[type="text"]::placeholder {opacity: 0.5; color: white; font-family: 'Open Sans', sans-serif; font-size: 12px; font-weight: 300; line-height: 35px; }
    }
    input[type="submit"] {
      text-indent: -996px;
      border: none;
      background-color: unset;
      background-image: url(../images/magnifying-glass.png);
      background-repeat: no-repeat;
      position: absolute;
      top: 10px;
      right: 0;
      padding: 0;
      width: 16px;
    }
  }
}
.pane-reviews {
  .pane-content  {
    @include respond-down(md) {
      width: 100%;
    }
    .slick-prev {
      @include respond-down(md) {
        left: 0;
        background: url(../images/arr_prev.png) center no-repeat;
        top: 30px;
        background-size: 90%;
      }
    }
    .slick-next {
      @include respond-down(md) {
        right: 0;
        background: url(../images/arr_next.png) center no-repeat;
        top: 30px;
        background-size: 90%;
      }
    }
    .slide__content {
      @include respond-down(md) {
        padding: 0 20px!important;
      }
    }
  }
}
.disclamer {
  position: absolute;
  bottom: 60px;
  margin-left: 390px;
  margin-right: 165px;
  color: #b1b1b1;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  a {
    color: #b1b1b1;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  @include respond-down (lg) {
    margin: 0;
    width: 100%;
    padding: 0;
    bottom: 40px;
    left: 0;
    p{
      left: 0;
      width: 612px;
      margin: 0 auto;
      padding-left: 180px;
    }
  }
  @include respond-down (md) {
    margin: 0;
    p {
      width: 300px;
      padding-left: 0;
    }
  }
}
.webform-confirmation {
  background: #fff;
  .thx {
    text-align: center;
    padding-top: 130px;
    padding-bottom: 130px;
    p {
      color: #364e67;
      font-family: "Open Sans", sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 26px;
    }
    p:nth-child(2) {
      color: #364e67;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
.links {
  background: #fff;
}
.tablestar  {
  overflow-x: scroll;
}
.views-field-field-category-review {
  padding-top: 10px;
  padding-bottom: 27px;
  @include respond-down (lg) {
    text-align: right;
    margin-right: 25px;
  }
  @include respond-down (md) {
    text-align: center;
    margin-right: 0;
  }
  a {
    color: #111;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-decoration: underline;
  }
}
.field-name-field-top-content-article {
  .field-item {
    p:nth-child(first) {
      display: none;
    }
  }
}
.article-education-language-content {
  h2 {
    // margin-left: 14px;
    margin-bottom: 20px;
  }
}
.jspContainer {
  height: auto !important;
  // @media screen and (max-height: 600px) {
  //   min-height: 30vh;
  // }
  // @media screen and (max-height: 300px) {
  //   min-height: 15vh;
  // }
}
.jspPane {
  position: relative;
  // top: 0 !important;
}
body .webform-component-checkboxes .form-item-submitted-checkbox-1 {
  margin-top: -0.6em;
}
.view-blog-front .slick__slide .slide__content .views-field-nothing .opas-text .body {
  @include respond-down (lg) {
    line-height: 18px;
  }
}
.tab-info .active-mobail {
  display: block;
}
.page-node-8743 {
  tr{
    td:nth-child(3) {
      width: 140px;  
    }
    
  }
}
.block-picture-all-page {
  @include respond-down (lg) {
    flex-wrap: wrap;
  }
  @include respond-down (md) {
    flex-wrap: wrap;
  }
  .image {
    @include respond-down (md) {
      margin-left: 0!important;
    }
  }
}