#header {
  left: 0;
  top: 0;
  position: fixed;
  transition: all .2s ease-out;
  width: 100%;
  z-index: 900;
  @include respond-down(lg) {
    position: unset;
  }
  .header-top {
    background-color: #fff;
    height: 70px;
    @include respond-down(lg) {
      height: auto;
    }
    @include respond-down(md) {
      height: auto;
    }
    .row {
      position: relative;
      @include respond-down(lg) {
        margin-bottom: 17px;
      }
      @include respond-down(md) {
        margin-bottom: 10px;
      }
      .logo-wrap {
        float: left;
        @include respond-down(md) {
          float: unset;
          margin-top: 10px;
        }
        .header-logo-link {
          img {
            width: unset;
            height: 40px;
            margin-top: 10px;
            margin-left: -4px;
            @include respond-down(lg) {
              margin-top: 5px;
            }
            @include respond-down(md) {
              display: flex;
              margin: 0 auto;
              width: 168px;
            }
          }
        }
      }
    }
    #block-views-exp-v-search-node-page {
      display: none;
    }
    .block-- {
      float: left;
      @include respond-down(lg) {
        position: absolute;
        left: 0;
        top: 50%;
      }
      @include respond-down(md) {
        position: unset;
        float: unset;
        width: 230px;
        display: flex;
        margin: 0 auto;
      }
    }
  }
  .header-top-menu {
    float: left;
    .block__content {
      ul {
        margin: 0;
        padding: 0;
      }
      li {
        display: inline-block;
      }
      a {
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        padding: 14px 24px 14px 0;
        text-decoration: none;
        text-transform: uppercase;
        &:focus,
        &:hover {
          background: none;
        }
      }
    }
  }
  .search-block {
    float: left;
    input[type=text] {
      background: url(../images/search_icon.png) left center no-repeat;
      border-radius: 0;
      border: none;
      color: #fff;
      padding-left: 25px;
    }
    .views-submit-button {
      display: none;
    }
    @media (max-width: 767px) {
      display:none;
    }
  }
  .header-phone {
    float: left;
    margin-left: 45%;
    @include respond-down(lg) {
      margin-left: 220px;
    }
    @include respond-down(md) {
      float: none;
      margin: 0 auto;
      width: 210px;
      display: flex;
    }
    p {
      @include respond-down(lg) {
        margin-right: 0!important;
        margin-top: 0!important;
      }
      a {
        @include respond-down(lg) {
          margin-right: 0!important;
        }
      }
    }
    .block__content {
      line-height: 45px;
      img {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
      a {
        display: inline-block;
        margin-right: 14px;
        color: #0f161d;
        font-family: "Open Sans", sans-serif;
        font-size: 19px;
        font-weight: 700;
        line-height: 19px;
        text-decoration: none;
        vertical-align: middle;
      }
      p {
        padding: 0;
        margin-top: 9px;
      }
    }
  }
  .call-block {
    float: right;
    @include respond-down(md) {
      float: none;
      margin: 0 auto;
      width: 216px;
      margin-bottom: 14px;
    }
    .block__content {
      a {
        display: inline-block;
        margin-top: 13px;
        outline: none;
        text-decoration: none;
        width: 214px;
        height: 43px;
        background-color: #fc6701;
        border-radius: 5px;
        padding: 17px 18px;
        color: #fff;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 11px;
        text-align: center;
        text-transform: uppercase;
        &:hover{
          background-color: #27aee6;
        }
        @include respond-down(lg) {
          margin-top:3px;
        }
      }
    }
  }
  .header-bottom {
    background-color: #364e67;
    .search-schools {
      display: none;
      @include respond-down(lg) {
        display: inline-block;
        margin-left: 16px;
      }
      @include respond-down(md) {
        display: inline-block;
      }
      .form-item-Searchworld {
        width: 160px;
        @include respond-down(md) {
          margin-top: 5px;
        }
        input[type="text"] {
          background-color: unset;
          border-top: none;
          border-radius: unset;
          border-left: none;
          border-right: none;
          color: #fff;
          box-shadow: none;
          border-bottom: 1px solid #c3e7ff;
          padding-left: 20px;
          @include respond-down(md) {
            padding-left: 35px;
          }
        }
        input[type="text"]::placeholder {opacity: 0.5; color: white; font-family: 'Open Sans', sans-serif; font-size: 12px; font-weight: 300; line-height: 35px; }
      }
      input[type="submit"] {
        text-indent: -996px;
        border: none;
        background-color: unset;
        background-image: url(../images/magnifying-glass.png);
        background-repeat: no-repeat;
        position: absolute;
        top: 10px;
        left: 7px;
        width: 16px;
        padding: 0;
        @include respond-down(lg) {
          left: 16px;
        }
        @include respond-down(md) {
          left: 16px;
          top: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
  &.fixed-scroll-border {
    border-bottom: 1px solid #ebebeb;
  }
  .nav-menu-icon {
    float: right;
    height: 24px;
    position: relative;
    top: 30px;
    transition: all .2s ease-out;
    width: 24px;
    @include respond-down(lg) {
      position: unset;
      margin-top: 12px;
      margin-right: 49px;
    }
    @include respond-down(md) {
      margin-right: 22px;
    }
    a {
      cursor: pointer;
      display: inline-block;
      height: 27px;
      width: 30px;
      i {
        background: #fff;
        position: relative;
        display: inline-block;
        width: 29px;
        height: 5px;
        text-transform: uppercase;
        text-indent: -55px;
        &:after,
        &:before {
          background: #fff;
          box-sizing: border-box;
          content: '';
          height: 5px;
          left: 0;
          position: absolute;
          transition: all .2s ease-out;
          width: 29px;
        }
        &:before {
          top: -7px;
        }
        &:after {
          bottom: -7px;
        }
        @include respond-down(lg) {
          &:before {
            top: -10px;
          }
          &:after {
            bottom: -11px;
          }
        }
      }
      &:hover {
        i:before {
          top: -10px;
        }
        i:after {
          bottom: -10px;
        }
      }
    }
  }
  .fixetburger {
    position: fixed;
    right: 30px;
    top: 20px;
    margin: 0;
    width: 40px;
    height: 40px;
    background-color: gray;
    z-index: 997;
    opacity: 0.8;
    text-align: center;
    padding: 3px 4px;
    a {
      i {
        height: 3px;
        background: #fff;
        width: 25px;
        &:before {
          height: 3px;
          background: #fff;
          top: -7px;
          width: 25px;
        }
        &:after {
          height: 3px;
          background: #fff;
          bottom: -7px;
          width: 25px;
        }
      }
    }
  }
}
.admin-menu #main-menu {
  > li {
    >ul{
      max-height: calc(100vh - 129px - 29px);
    }
  }
}
#main-menu {
  > li {
    &:hover{
      >ul{
        visibility: visible;
        opacity: 1;
        transform: translateY(0%);
      }
    }
    >ul {
      padding: 0;
      min-width: 300px;
      background: #fff;
      box-shadow: 0 4px 5px 0 rgba(0,0,0,.1);
      transition: all .35s ease-out;
      border-radius: 0;
      visibility: hidden;
      opacity: 0;
      transform: translateY(10%);
      display: block;
      border: 0;
      overflow-y: auto;
      max-height: calc(100vh - 129px);
      >li {
        font-size: 14px;
        margin-left: 15px;
        position: relative;
        &:hover{
          >a{
            background: #fafafa;
          }
        }
        >a{
          display: block;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: #0084bf;
          padding: 6px 5px;
          border-bottom: 1px solid #eee;
          .fa-caret-right {
            &:before {
              content: none;
            }
          }
        }
      }
    }
    > a {
      background: none;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.1px;
      padding: 20px 10px;
      text-decoration: none;
      span {
        position: relative;
        top: 1px;
      }
      &:after {
        background: url(../images/line_white.png) no-repeat;
        content: '';
        height: 13px;
        right: -3px;
        position: absolute;
        width: 1px;
        top: 23px;
      }  
      &:last-child:after {
        content: none;
      }
      &:hover,
      &:focus {
        background: none;
      }
      .fa-caret-down {
        bottom: -21px;
        color: #fff;
        font-size: 20px;
        left: 50%;
        //position: absolute;
        margin-left: 5px;
        transform: translateX(-50%);
        z-index: 2;

        &:before {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 5px solid #fff;
          bottom: 0;
          content: '';
          height: 0;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 0;
          z-index: 2;
        }
        &.scroll {
          &:after {
            background: url(../images/menu_arrow_down.png) center no-repeat;
            bottom: 5px;
            content: '';
            height: 5px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 9px;
          }
        }
      }
    }
    &.last {
       > a {
        padding-right: 0;
      }
    }
    &.first {
       > a {
        padding-left: 0;
      }
    }
  }
  > li:nth-child(4) {
    > ul {
      max-height: unset;
      overflow-y: unset;
    }
  }
}

#mobile-menu-container {
  background-color: #0095d9;
  bottom: 0;
  box-shadow: -1px 0 15px rgba(0,0,0,.5);
  position: fixed;
  right: -100%;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 999;
  &.opened {
    right: 0;
  }
  > .close {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    left: 20px;
    opacity: 1;
    position: absolute;
    top: 10px;
    z-index: 2;
  }
  .inner {
    height: 100%;
    overflow: auto;
    padding: 10px;
    width: 100%;
  }
  .block__content {
    color: #fff;
    a {
      color: #fff;
    }
  }
  .call-block {
    margin: 30px 0 10px 0;
    .block__content {
      padding: 10px 0;
      text-align: center;
      a {
        text-decoration: none;
      }
    }
  }
  .header-top-menu,
  .block-main-menu {
    .block__content {
      a {
        &:focus,
        &:hover {
          background: none;
        }
      }
      .caret {
        background: #fff;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        height: 25px;
        line-height: 25px;
        margin: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 7px;
        transition: all 0.3s ease;
        width: 25px;
        &:before {
          content: '+';
          color: #0095d9;
          font-size: 20px;
          font-weight: 100;
          left: 0;
          line-height: 25px;
          position: absolute;
          top: 0;
          text-align: center;
          width: 25px;
        }
        &.opened {
          &:before {
            content: '-';
            line-height: 21px;
            font-size: 25px;
          }
        }
      }
      li {
        &.expanded {
          a {
            position: relative;
          }
          > ul {
            display: none;
          }
        }
      }
    }
  }
  .header-phone {
    float: left;
    @include respond-down(lg) {
      float: none;
      margin: 0 auto;
      width: 210px;
      display: flex;
    }
    p {
      @include respond-down(lg) {
        margin-right: 0!important;
        margin-top: 0!important;
      }
      a {
        @include respond-down(lg) {
          margin-right: 0!important;
        }
      }
    }
    .block__content {
      line-height: 45px;
      img {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        background-color: white;
        border: 2px solid aliceblue;
        border-radius: 15px;
      }
      a {
        display: inline-block;
        margin-right: 14px;
        color: #0f161d;
        font-family: "Open Sans", sans-serif;
        font-size: 19px;
        font-weight: 700;
        line-height: 19px;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
      }
      p {
        padding: 0;
        margin-top: 9px;
      }
    }
  }
}
.admin-menu {
  #mobile-menu-container {
    top: 29px;
  }
}

#modalContent #modal-content {
  overflow: unset;
}  
.dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  li {
    a {
      display: block;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #0084bf;
      padding: 6px 5px;
      border-bottom: 1px solid #eee;
      &:hover {
        color: #0084bf;
      }
    }
  }
}