.view-id-langcourses_faq {
  .views-row {
    margin-bottom: 70px;
    padding-left: 30px;
  }
  .image-container {
    height: 160px;
  }
  .button-s-2 {
    display: none;
  }
}
.pane-webform-client-block-4364 {
  padding-bottom: 50px;
}
.article-education-language-form-content {
  padding-right: 15px;
}
.article-education-language-form-wrapper {
  background: #E2EDF3;
  padding: 18px 20px;
  .sidebar-title {
    font-size: 22px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 27px;
    text-transform: uppercase;
  }
  form {
    margin-top: 30px;
    .form-item {
      display: inline-block;
      margin: 20px 0 15px 0;
      width: 100%;
    }
    select {
      appearance: none;
      background-color: #fff;
      border-radius: 25px;
      border: none;
      color: #777;
      font-size: 14px;
      height: 50px;
      outline: none;
      padding: 0 20px;
      transition: all .3s ease-in;
      width: 100%;
    }
    input[type=submit] {
      @extend .c-button;
      @extend .w100;
      @extend .h50;
      background-color: #23b0e8;
      border: 2px solid #23b0e8;
      outline: none;
      &:hover {
        background: none;
        color: #23b0e8;
      }
    }
  }
}
.article-education-language-content {
  .grid-content {
    margin-right: -15px;
    .hotel-item {
      margin-bottom: 30px;
      .caption {
        height: 38px;
      }
    }
  }
  .list-item-entry {
    //padding: 0 15px;
    background-color: #f8f8f8;
  }
} 


