#modalContent {
  @include respond-down(md) {
    max-width: 100%!important;
    top: 0!important;
    left: 0!important;
    position: fixed!important;
  }
  #modal-content {
    min-height: 150px;
    @include respond-down(md) {
      padding-top: 10px;
    }
    .loading-spinner {
      top: calc(50% - 54px)!important;
      left: calc(50% - 25px)!important;
    }
    .form-actions {
      @include respond-down(md) {
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }
  .close {
    outline: none;
  }
  .modal-header {
    border: none;
    @include respond-down(md) {
      padding-bottom: 0px;
    }
  }
}
.silver-modal-dialog {
  max-width: calc(100% - 20px)!important;
}
.silver-modal-header {
  .modal-title {
    text-transform: uppercase;
    @include respond-down(md) {
      line-height: 17px;
      font-size: 16px;
    }
  }
}
.silver-modal-body {
  .webform-confirmation {
    text-align: center;
  }
  .links {
    display: none;
  }
  .webform-client-form {
    .form-item {
      margin-bottom: 10px;
    }
    input[type=text] {
      background: #fff;
      border: 1px solid rgba(255,255,255,.5);
      border-radius: 25px;
      color: #777;
      font-size: 14px;
      height: 50px;
      opacity: 1;
      padding: 0 20px 0 44px;
      border-color: #a9a9a9;
      .error {
        border-color: red;
      }
    }
    textarea {
      background: #fff;
      border: 1px solid #a9a9a9;
      border-radius: 25px;
      box-shadow: none;
      color: #b9b9b9;
      font-size: 14px;
      height: 100px;
      opacity: 1;
      padding: 15px 20px 0 44px;
      resize: none;
    }
    .form-actions {
      @extend .col-md-12;
    }
    input[type=submit] {
      @extend .c-button;
      @extend .bg-3;
      @extend .w100;
      @extend .h50;
      margin: 0;
      &:hover {
        background: none;
        color: #23b0e8;
      }
    }
    .form-email {
      background: #fff;
      border: 1px solid rgba(255,255,255,0.5);
      border-radius: 25px;
      color: #777;
      font-size: 14px;
      height: 50px;
      opacity: 1;
      padding: 0 20px 0 44px;
      border-color: #a9a9a9;
      font-size: 14px;
    }
  }
}
.webform-component--soglasie-na-obrabotku {
  font-size: 12px;
  text-align: center;
  margin: 10px 0;
  @include respond-down(md) {
    margin-bottom: 0px!important;
  }
  p {
    @include respond-down(md) {
      margin-bottom: 0px;
      padding-bottom: 0px;
      line-height: 14px;
    }
  }
}

.webform-component---obyazatelnoe-dlya-zapolneniya-pole {
  color: #463535;
  font-size: 14px;
  @include respond-down(md) {
    margin-bottom: 0px!important;
  }
  p {
    @include respond-down(md) {
      padding-bottom: 0px;
    }
  }
}